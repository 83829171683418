
import * as React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import UserGeneralData from "./user-general-data.component";
import UserActiveAgents from "./user-active-agents.component";
import { UserLoginActivities } from "./user-login-activities.component";
import { DwsimPropUsage } from "./dwsim-pro-usage.component";
import { getPanelUsersClient } from "../../../services/api-clients.service";

interface IDeleteUserModalProps {
    userId: string;
    userDisplayName: string;
    show: boolean;
    onSuccess: () => void;
    onHide: () => void;
}



const DeleteUserModal: React.FunctionComponent<IDeleteUserModalProps> = (props) => {

    const [isRequestInProgress, setIsRequestInProgress] = React.useState<boolean>(false);

    const onDeleteClick = async () => {

        try {
            setIsRequestInProgress(true);

            const client = await getPanelUsersClient();
            await client.deleteUser(props.userId);
            toast.success("User deleted successfully.");
            setIsRequestInProgress(false);
            props.onSuccess();

        } catch (error) {
            setIsRequestInProgress(false);
            toast.error("An error occurred while deleting user.");
        }


    }


    return <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
        size="lg"
        centered
        backdropClassName="modal-backdrop-2"
        dialogClassName="max-width-500"
    >
        <Modal.Header closeButton={isRequestInProgress ? false : true}>

            <Modal.Title>
                Delete user
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>

            {!isRequestInProgress && <h4>Are you sure you want to <b>delete</b> user  <b>'{props.userDisplayName}'</b>?</h4>}
            {isRequestInProgress && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                <Spinner animation="border" variant="info" className="me-4" /><h4 className="m-0">Deleting...</h4>
            </div>
            }

        </Modal.Body>

        <Modal.Footer>
            <div className="d-flex justify-content-between">
                <div className="form-group d-flex">
                    <button type="button" disabled={isRequestInProgress} onClick={() => { onDeleteClick() }} className={'btn btn-danger ml-2'}>
                        Delete
                    </button>
                </div>


                <div className="form-group d-flex">
                    <button type="button" disabled={isRequestInProgress} onClick={props.onHide} className={'btn btn-info ml-2'}>
                        Cancel
                    </button>
                </div>
            </div>
        </Modal.Footer>

    </Modal>
}


export default DeleteUserModal;