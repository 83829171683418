import * as React from "react";
import ContentWrapper from '../../layout/components/content-wrapper/content-wrapper.component';
import SubHeader from '../../layout/components/sub-header/sub-header.component';
import { Card } from 'react-bootstrap';
import { toast } from "react-toastify";
import { HostPoolResultModel, HostsClient } from "../../swagger-clients/s365-admin-panel-clients.service";
import UserNotificationModal from "./user-notification-modal/user-notification-modal.component";

import "./host-pools.styless.scss";
import LoadingService from "../../components/loading-indicator/loading-indicator.service";

interface IHostPoolsPageProps {

}
interface IHostPoolsPageState {
    hostPools: HostPoolResultModel[];
    selectedPool?: HostPoolResultModel;
    showUserNotificationModal: boolean;
}

class HostPoolsPage extends React.Component<IHostPoolsPageProps, IHostPoolsPageState>{


    constructor(props: IHostPoolsPageProps) {
        super(props);
        this.state = {
            hostPools: [],
            showUserNotificationModal: false
        };

    }

    async componentDidMount() {
        await this.getHostPools();
    }
    async getHostPools() {
        try {
            LoadingService.showLoading();
            const client = new HostsClient(process.env.REACT_APP_BASE_URL);
            const hostPools = await client.getHosts();

            this.setState({ hostPools: hostPools });
        }
        catch (error) {
            console.log("An error occurred while trying to get host pools.", error);
            toast.error("An error occurred while trying to get host pools.");
        } finally {
            LoadingService.hideLoading();
        }
    }

    render() {
        const { hostPools, showUserNotificationModal, selectedPool } = this.state;

        return [<SubHeader key="sub-header" title="Host pools" leftItems={[]} rightItems={[]} />,
        <ContentWrapper key="content-wrapper">
            <Card style={{ width: "1200px", marginRight: "auto", marginLeft: "auto" }}>

                <Card.Body>
                    <table className="table host-pools-table">
                        <thead>
                            <tr>
                                <th scope="col">
                                    Name
                                </th>
                                <th scope="col">
                                    Pool type
                                </th>
                                <th scope="col">
                                    Max sessions
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {hostPools.map((hostPool) => (
                                <tr
                                    key={hostPool.id}
                                    className={selectedPool && selectedPool.name === hostPool.name ? "host-pool-selected" : ""}
                                    onClick={() => { this.setState({ selectedPool: hostPool, showUserNotificationModal: true }) }}>
                                    <td>{hostPool.name}</td>
                                    <td>{hostPool.hostPoolType}</td>
                                    <td>{hostPool.maxSessionLimit}</td>
                                </tr>
                            ))
                            }

                        </tbody>
                    </table>

                    {showUserNotificationModal &&
                        <UserNotificationModal
                            hostPoolName={selectedPool?.name ?? ""}
                            resourceGroupName={selectedPool?.resourceGroupName ?? ""}
                            show={true}
                            onHide={() => { this.setState({ showUserNotificationModal: false, selectedPool: undefined }) }} />
                    }
                </Card.Body>
            </Card>
        </ContentWrapper>]
    }
}

export default HostPoolsPage;