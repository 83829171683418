import moment from "moment";
import React, { ReactElement } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

type DateTimeDisplayUTCProps = {
    date: Date;
    hideTime?: boolean;
    dateFormat?: string;
    timeFormat?: string;
    multiline?: boolean;
}

export const DateTimeDisplayUTC: React.FC<DateTimeDisplayUTCProps> = (props) => {

    const dateFormat: string = props.dateFormat ? props.dateFormat : "DD.MM.YYYY";
    const timeFormat: string = props.timeFormat ? props.timeFormat : "HH:mm";
    let format = `${dateFormat}${props.hideTime ? "" : ` ${timeFormat}`}`;


    if (props.date) {
        if (!props.multiline) {
            return <UTCPopover><span>{moment.utc(props.date).format(format)}</span></UTCPopover>;
        } else {
            return <UTCPopover>
                <span>{moment.utc(props.date).format(dateFormat)}
                <br />{moment.utc(props.date).format(timeFormat)}  </span>
            </UTCPopover>;
        }
    }
    return null;
}

type UTCPopoverProps = {
    children?: any;
}
const UTCPopover: React.FC<UTCPopoverProps> = (props) => {


    return <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={UtcTimePopover}>
        {props.children}
    </OverlayTrigger>
}

export const UtcTimePopover = (
    <Popover id="popover-positioned-top" >
        <span style={{ padding: "5px" }}> UTC</span>
    </Popover>
);