import * as React from "react";
import { Control,   FormState, useFieldArray } from "react-hook-form";
import { OrganizationPostModel } from "../../../../swagger-clients/dashboard-clients.service";

interface IInputListProps {
    register: any;
    control: Control<OrganizationPostModel>;
    setValue: any;
    getValues: any;
    formState: FormState<OrganizationPostModel>;
    
}




const InputList: React.FunctionComponent<IInputListProps> = (props) => {

    const { control, register, setValue, getValues, formState: { errors } } = props;
    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "domains"
    });



    return <div>
        {
            fields.map((item:OrganizationPostModel, index) => {
                // console.log("item:", item, props.errors);
                // console.log("domains:",  props.errors.domains);
                return <div style={{ marginBottom: "5px" }}>
                    <div className="input-group">
                        <input
                            key={item.id}
                            className="form-control"
                            {...register(`domains.${index}.name` as const, { required: true, pattern: /^[^\s]+$/ })}
                            defaultValue={item.name}
                        />
                        <div className="input-group-prepend">
                            <button type="button" style={{ color: "red", borderRadius: "0 0.42rem 0.42rem 0" }} className="btn btn-outline-secondary" onClick={() => { remove(index) }}>X</button>
                        </div>

                    </div>

                    {errors !== undefined && errors.domains !== undefined
                        && errors.domains[index] !== undefined
                        && errors.domains[index]!.name !== undefined
                        && errors.domains[index]!.name?.type === "required"
                        && <p className="text-danger">Value is required!</p>}
                    {errors !== undefined && errors.domains !== undefined
                        && errors.domains[index] !== undefined
                        && errors.domains[index]!.name !== undefined
                        && errors.domains[index]!.name?.type === "pattern"
                        && <p className="text-danger">Invalid domain name!</p>}                   
                </div>




            })
        }
        <button type="button" className="btn btn-sm btn-primary my-1" onClick={() => { append({ name: "" } as any); }} >Add Domain</button> 

    </div>


}

export default InputList;