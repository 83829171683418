import * as React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getOrganizationsClient } from "../../../services/api-clients.service";
import { ErrorResultModel, OrganizationsClient } from "../../../swagger-clients/dashboard-clients.service";

interface IDeleteOrganizationModalProps {
    organizationId: number;
    organizationName: string;
    show: boolean;
    onHide: () => void;
    onOrganizationDeleted: () => void;
}

const DeleteOrganizationModal: React.FunctionComponent<IDeleteOrganizationModalProps> = (props) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);


    const deleteOrganization = async () => {
        try {
            setIsLoading(true);
            const client = getOrganizationsClient();
            await client.deleteOrganization(props.organizationId);
            props.onOrganizationDeleted();

        } catch (error) {
            const errorResponseModel = error as ErrorResultModel;
            if (errorResponseModel && errorResponseModel.errors && errorResponseModel.errors.length > 0) {
                toast.error(errorResponseModel.errors[0]);
            } else {
                toast.error("An error occurred while deleting organization.");
            }
            console.log("An error occurred while deleting organization.", error);
        } finally {
            setIsLoading(false);
        }
    }

    return <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                Organization details
    </Modal.Title>
        </Modal.Header>

        <Modal.Body>

            <p>Are you sure that you want to delete "{props.organizationName}" organization?</p>

        </Modal.Body>

        <Modal.Footer>
            <div className="form-group d-flex float-right">
                <button type="button" className={'btn btn-primary'} disabled={isLoading} onClick={() => deleteOrganization()}>
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
                    {"Delete"}
                </button>
                <button type="button" onClick={props.onHide} className={'btn btn-danger ml-2'} disabled={isLoading}>
                    {"Cancel"}
                </button>

            </div>
        </Modal.Footer>

    </Modal>
}



export default DeleteOrganizationModal;