export default class LoadingService {
    private static showCount: number = 0;

    private static _callbackFunctions: any[] = [];

    static onStatusChanged(isLoading: boolean) {
        if (LoadingService._callbackFunctions && LoadingService._callbackFunctions.length > 0) {
            for (let f of LoadingService._callbackFunctions) {
                f(isLoading);
            }
        }
    }

    static addCallback(callbackFunc: any) {
        LoadingService._callbackFunctions.push(callbackFunc);
    }



    static reset() {
        LoadingService.showCount = 0;
    }
    static isLoading() {
        return this.showCount > 0;
    }

    static showLoading() {
        //   console.log("LoadingService showloading", LoadingService.showCount);
        LoadingService.showCount++;
        if (this.showCount > 0)
            this.onStatusChanged(true);
    }

    static hideLoading() {
        //  console.log("LoadingService hideLoading", LoadingService.showCount);
        LoadingService.showCount--;
        if (LoadingService.showCount <= 0) {
            this.reset();
            LoadingService.onStatusChanged(false);
        }

    }

}