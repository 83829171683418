import {createStore, applyMiddleware} from 'redux';
import { createBrowserHistory } from 'history';
import logger from 'redux-logger';
import createRootReducer from './root-reducer';




export const history = createBrowserHistory();

const middlewares=[logger];
const store = createStore( createRootReducer(history), applyMiddleware(...middlewares));


export default store;
