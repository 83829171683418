import * as React from "react";
import { Card } from "react-bootstrap";
import ContentWrapper from "../../layout/components/content-wrapper/content-wrapper.component";
import SubHeader from "../../layout/components/sub-header/sub-header.component";
import ReindexRequestsList from "./components/reindex-requests-list.component";
interface IElastiSearchPageProps {

}

interface IElasticSearchPageState {
    selectedTab: number;
}

class ElasticSearchPage extends React.Component<IElastiSearchPageProps, IElasticSearchPageState>{


    /**
     *
     */
    constructor(props: IElastiSearchPageProps) {
        super(props);
        this.state = { selectedTab: 1 };
    }


    render(): React.ReactNode {
        const { selectedTab } = this.state;

        return [<SubHeader key="sub-header" title="Users" leftItems={[]} rightItems={[]} />,
        <ContentWrapper key="content-wrapper">
            <Card style={{ width: "1200px", marginRight: "auto", marginLeft: "auto" }}>
                <Card.Body>

                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className={`nav-link ${selectedTab == 1 ? "active" : ""}`} onClick={() => this.setState({ selectedTab: 1 })} href="#">MSS</a>
                        </li>

                        <li className="nav-item">
                            <a className={`nav-link ${selectedTab == 2 ? "active" : ""}`} onClick={() => this.setState({ selectedTab: 2 })} href="#">Excel Runner</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${selectedTab == 3 ? "active" : ""}`} onClick={() => this.setState({ selectedTab: 3 })} href="#">Dispatcher</a>
                        </li>

                    </ul>
                    {selectedTab == 1 && <ReindexRequestsList key="mss" serviceUrl={process.env.REACT_APP_SENSITIVITY_STUDY_SERVICE_URL} />
                    }

                    {selectedTab == 2 && <ReindexRequestsList key="excel-runner" serviceUrl={process.env.REACT_APP_EXCEL_RUNNER_SERVICE_URL} />}

                    {selectedTab == 3 && <ReindexRequestsList key="dispatcher" serviceUrl={process.env.REACT_APP_DISPATCHER_SERVICE_URL} />}
                </Card.Body>

            </Card>
        </ContentWrapper>];
    }


}


export default ElasticSearchPage;