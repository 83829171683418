import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import "./image-modal.styless.scss"
import { ReactZoomPanPinchContentRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

interface IImageModalProps {
    title: React.ReactElement | string;
    imgSrc: string;
    show: boolean;
    showNextButton: boolean;
    showPreviousButton: boolean;
    onNextClick: () => void;
    onPreviousClick: () => void;
    onHide: () => void;
}

const ImageModal: React.FunctionComponent<IImageModalProps> = (props) => {

    const [currentScale, setCurrentScale] = React.useState<number>(1);

    return <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
        dialogClassName="image-dialog"
    >
        <TransformWrapper initialScale={1} onTransformed={(ref, state) => { setCurrentScale(state.scale) }}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }: any) => (
                <React.Fragment>
                    <Modal.Header closeButton={true} style={{ padding: '10px' }}>
                        <Modal.Title>
                            {props.title}
                        </Modal.Title>

                        <div className="tools"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                marginLeft: "auto",
                                alignItems: "center",
                                paddingRight: "30px"
                            }}
                        >

                            <span style={{ padding: "0px 5px" }}>{(currentScale * 100).toFixed(0)}%</span>
                            <a
                                style={{
                                    userSelect: "none",
                                    padding: "0px 5px",
                                    fontSize: "18px",
                                    cursor: "pointer"
                                }}
                                onClick={() => { zoomIn() }}>
                                <span className="fas fa-search-plus"> </span>
                            </a>
                            <a
                                onClick={() => { zoomOut() }}
                                style={{
                                    userSelect: "none",
                                    padding: "0px 5px",
                                    fontSize: "18px",
                                    cursor: "pointer"
                                }}
                            >
                                <span className="fas fa-search-minus"> </span>
                            </a>
                            <a
                                onClick={() => { resetTransform() }}
                                style={{
                                    userSelect: "none",
                                    padding: "0px 5px",
                                    fontSize: "18px",
                                    cursor: "pointer"
                                }}
                            >
                                <span className="fas fa-expand"> </span>
                            </a>


                        </div>

                    </Modal.Header>

                    <Modal.Body style={{ padding: '10px' }}>
                        <div className='d-flex'>
                            {props.showPreviousButton && <div className='d-flex flex-column justify-content-center'>
                                <Button variant="link" onClick={props.onPreviousClick}> <i className="fas fa-chevron-left"></i></Button>
                            </div>}
                            <TransformComponent wrapperStyle={{ cursor: "grab" }}>
                                <img src={props.imgSrc} alt="screenshot" style={{ maxWidth: "100%", maxHeight: "calc(90vh - 100px)" }} />
                            </TransformComponent>
                            {props.showNextButton && <div className='d-flex flex-column justify-content-center'>
                                <Button variant="link" onClick={props.onNextClick}> <i className="fas fa-chevron-right"></i></Button>
                            </div>}
                        </div>
                    </Modal.Body>

                </React.Fragment>
            )}
        </TransformWrapper>
    </Modal>
}


export default ImageModal;