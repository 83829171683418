import React from 'react';
import { Card } from 'react-bootstrap';
import ContentWrapper from '../../layout/components/content-wrapper/content-wrapper.component';
import SubHeader from '../../layout/components/sub-header/sub-header.component';

interface IHomePageProps {

}

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {

    return <>
        <SubHeader key="sub-header" leftItems={[]} rightItems={[]} />
        <ContentWrapper key="content-wrapper">
            <Card style={{ width: "1200px", marginRight: "auto", marginLeft: "auto" }}>
                <Card.Body>
                    <div style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "40px" }}>Welcome to Simulate 365 Admin Panel!</p>
                    </div>


                </Card.Body>
            </Card>
        </ContentWrapper>
    </>
}


export default HomePage;
