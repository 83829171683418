import React from "react";
import { authenticationService } from '../../../../services/authentication-service';
import { connect } from 'react-redux';
import { AuthenticationClient, UserResultModel } from "../../../../swagger-clients/s365-admin-panel-clients.service";
import { Dropdown } from 'react-bootstrap';

interface ITopbarProps {
    user?: UserResultModel;
}

const Topbar: React.FunctionComponent<ITopbarProps> = (props) => {

    const onLogoutClick = async () => {
        try {

            const client = new AuthenticationClient(process.env.REACT_APP_BASE_URL);
            await client.externalLogout();
            window.location.href=`${process.env.REACT_APP_BASE_URL}`;

        } catch (error) {
            console.log("An error ocurred while trying to logout.", error);
        }
    }


    const GetFirstLetter = (word: string): string => {
        return word.length > 0 ? word[0].toString() : "";
    }

    return <div className="topbar">

        <Dropdown>
            <Dropdown.Toggle style={{ backgroundColor: "transparent", border: "none" }} id="dropdown-custom-1">
                <div className="topbar-item">
                    <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
                        <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                        <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{props.user ? props.user.displayName : ""}</span>
                        <span className="symbol symbol-lg-35 symbol-25 symbol-light-success">
                            <span className="symbol-label font-size-h5 font-weight-bold">{props.user ? GetFirstLetter(props.user.displayName) : ""}</span>
                        </span>
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
                <Dropdown.Item onClick={onLogoutClick} >Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>


    </div>;
}


const MapStateToProps = (state: any) => ({
    user: state.application.user
})

export default connect(MapStateToProps)(Topbar)