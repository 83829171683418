import React from 'react';
import AsideMenu from './aside-menu.component';
import logo from '../../../assets/images/S365_logo_big.png';
import SideBarPlaceholder from '../../../components/sidebar-placeholder/sidebar-placeholder.component';

const Aside = () => {
  return <>
    {/* begin::Aside */}
    <div id="kt_aside"
      className="aside aside-left  aside-fixed  d-flex flex-column flex-row-auto">

      {/* begin::Aside Menu */}
      <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper d-flex flex-column" style={{ height: '100vh' }}>

        <>
          {/* begin::Header Logo */}
          <div className="header-logo d-flex align-items-center" style={{margin:"10px"}}>

            <img src={logo} alt="logo" style={{ marginLeft: 10, marginRight: 10, height: 70 }} />
            <span style={{ fontSize: "20px", paddingLeft: "5px", lineHeight:"30px" }}>
              <span style={{ fontWeight: 600 }}>
                Simulate 365
                </span>
              <br />
               Admin Panel
                
            </span>

          </div>
          {/* end::Header Logo */}
        </>
        <AsideMenu />
        <SideBarPlaceholder/>
      </div>
      {/* end::Aside Menu */}
    </div>
    {/* end::Aside */}
  </>
}

export default Aside;