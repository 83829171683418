import React from "react";
import { DwsimProUsagePerUserModel, GetDwsimProUsagePostModel } from "../../../swagger-clients/s365-admin-panel-clients.service";
import { UtcTimePopover } from "../../screenshots/screenshots.component";
import { Alert, OverlayTrigger } from "react-bootstrap";
import { ShowErrorFromResponse } from "../../../utilities/response-processor";
import { getDwsimProClient } from "../../../services/api-clients.service";
import LoadingService from "../../../components/loading-indicator/loading-indicator.service";
import { DateTimeDisplayUTC } from "../../../components/date-time-display/date-time-display.component";

type DwsimPropUsageProps = {
    userPrincipalName: string;
}

export const DwsimPropUsage: React.FC<DwsimPropUsageProps> = (props) => {

    const [usage, setUsage] = React.useState<DwsimProUsagePerUserModel>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        getUsage();
    }, []);

    const getUsage = async () => {
        try {
            LoadingService.showLoading();
            setIsLoading(true);
            const client = getDwsimProClient();
            const usernameWithoutDomain = props.userPrincipalName.split("@")?.[0] ?? "";

            const model = new GetDwsimProUsagePostModel({
                usernameWithoutDomain: usernameWithoutDomain
            });
            const usageResp = await client.getDwsimProUsage(model);
            setUsage(usageResp);

        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while getting user's usage data.");

        } finally {
            LoadingService.hideLoading();
            setIsLoading(false);
        }


    }

    return <div className="d-flex flex-column align-items-center">
        {!!usage && usage.maxRowsLoaded &&
            <Alert variant="warning" className="my-4">
                Maximum number of rows was loaded which will cause data inaccuracy for an extended time range.
            </Alert>}

        <table className="table mt-4" style={{ maxWidth: "400px" }}>
            <thead>
                <tr>
                    <th></th>
                    <th className="text-center">Days used</th>
                    <th className="text-center">Screenshots</th>
                </tr>
            </thead>
            <tbody>

                {!!usage && !isLoading && <>
                    <tr>
                        <td style={{ fontWeight: 600 }}>Last 7 days</td>
                        <td className="text-center">{usage.last7Days?.daysUsed ?? "-"}</td>
                        <td className="text-center">{usage.last7Days?.screenshotsFound ?? "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 600 }}>Last 30 days</td>
                        <td className="text-center">{usage.last30Days?.daysUsed ?? "-"}</td>
                        <td className="text-center">{usage.last30Days?.screenshotsFound ?? "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 600 }}>Last 365 days</td>
                        <td className="text-center">{usage.last365Days?.daysUsed ?? "-"}</td>
                        <td className="text-center">{usage.last365Days?.screenshotsFound ?? "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 600 }}>Last visit</td>
                        <td colSpan={2} className="text-center">
                            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={UtcTimePopover}>
                                <span>  {usage.lastVisit ? <DateTimeDisplayUTC date={usage.lastVisit} hideTime /> : "-"}</span>
                            </OverlayTrigger>
                        </td>

                    </tr>

                </>
                }
                {
                    !isLoading && !usage && <tr className="text-center">
                        <td colSpan={3} >
                            No data found.
                        </td>
                    </tr>
                }
            </tbody>

        </table>
    </div>
}