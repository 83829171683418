import * as React from "react";
import { toast } from "react-toastify";
import LoadingService from "../../../components/loading-indicator/loading-indicator.service";
import { getUserLoginsClient } from "../../../services/api-clients.service";
import { UserLoginActivityResponseModel, UserLoginsClient } from "../../../swagger-clients/s365-usage-tracking-clients.service";
import { IPagination, UtcTimePopover } from "../../screenshots/screenshots.component";
import TimeAgo from 'react-timeago';
import ReactPaginate from "react-paginate";
import { OverlayTrigger } from "react-bootstrap";
import { DateTimeDisplayUTC } from "../../../components/date-time-display/date-time-display.component";

interface IUserLoginActivitiesProps {
    userId: string;
}

export const UserLoginActivities: React.FC<IUserLoginActivitiesProps> = (props) => {

    const [pagination, setPagination] = React.useState<IPagination>({
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: 20
    });

    const [activities, setActivities] = React.useState<UserLoginActivityResponseModel[] | undefined>();
    const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.userId) {
            getUserLoginActivities();
        }
    }, [props.userId, pagination.currentPage, pagination.recordsPerPage]);

    const getUserLoginActivities = async () => {
        try {
            LoadingService.showLoading();
            const client = getUserLoginsClient();
            setIsDataLoaded(false);
            const response = await client.getUserLoginActivity(props.userId, (pagination.currentPage - 1) * pagination.recordsPerPage, pagination.recordsPerPage);


            setPagination({ totalRecords: response.totalRecords ?? 0, currentPage: pagination.currentPage, recordsPerPage: pagination.recordsPerPage });

            setActivities(response.items);

        } catch (error) {
            console.log("An error ocurred while getting user login activities.", error);
            toast.error("An error ocurred while getting user login activities.");
        } finally {
            LoadingService.hideLoading();
            setIsDataLoaded(true);
        }
    }

    const { totalRecords, currentPage, recordsPerPage } = pagination;

    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    return <>
        <table className="table">
            <thead>
                <tr>
                    <th>Application name</th>
                    <th className="text-center">Date & time (UTC)</th>
                    <th className="text-center">IP</th>
                </tr>
            </thead>
            <tbody>
                {activities && activities.length > 0 && <>
                    {activities.map(activity => {

                        return <tr>
                            <td>{activity.applicationName ?? <em>Unknown application</em>}</td>
                            <td className="text-center"><OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={UtcTimePopover}>
                                <span><DateTimeDisplayUTC date={activity.loggedInAt!}/></span>
                            </OverlayTrigger>
                            </td>
                            <td className="text-center">{activity.ip}</td>
                        </tr>
                    })}

                </>
                }
                {
                    activities && activities.length == 0 && isDataLoaded && <tr className="text-center">
                        <td colSpan={7}>
                            No login activities found.
                        </td>
                    </tr>
                }
            </tbody>

        </table>
        {totalPages > 1 && <div className="form-group row mb-0 mt-4">
            <div className="col-10 d-flex">   <ReactPaginate

                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me page-item'}
                breakLinkClassName={'page-link'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                pageCount={totalPages}
                marginPagesDisplayed={4}
                pageRangeDisplayed={10}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                forcePage={currentPage - 1}
                onPageChange={(page) => { console.log("Selected page:", page); setPagination({ ...pagination, currentPage: page.selected + 1 }) }}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
                {totalRecords >= 10000 && <i className="fas fa-exclamation-triangle text-danger" style={{ fontSize: "21px", padding: "5px" }}
                    title="Pagination will only work for first 10,000 results. Reduce your time range." />}
            </div>

            <div className="col-2 d-flex justify-content-end">

                <label htmlFor="staticEmail" className=" col-form-label-sm text-right mr-1">Per page:</label>
                <select className="form-control-sm form-select" value={pagination.recordsPerPage}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        setPagination({ ...pagination, recordsPerPage: +event.target.value });
                    }}
                >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="10000">1000</option>
                </select>

            </div>

        </div>

        }
    </>;
}



