import React from 'react';
import HeaderMenu from './header-menu.component';
import Topbar from './topbar/topbar.component';

const Header = () => {
    return <div id="kt_header" className="header  header-fixed" >

        <div className=" container-fluid  d-flex align-items-stretch justify-content-between">
            <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                <HeaderMenu />
            </div>

            <Topbar />
        </div>
    </div>

}

export default Header;