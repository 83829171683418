import * as React from "react";
import { toast } from "react-toastify";
import { getSensitivityStudiesClient } from "../../../services/api-clients.service";
import { ReindexingRunModel } from "../../../swagger-clients/sensitivity-studies-clients.service";
import ReindexRequestDetailsModal, { getRequestStatus } from "./reindex-request-details-modal.component";
import { DateTimeDisplayUTC } from "../../../components/date-time-display/date-time-display.component";
interface IReindexRequestsListProps {
    serviceUrl?: string;
}

const ReindexRequestsList: React.FC<IReindexRequestsListProps> = (props) => {
    const [reindexRequests, setReindexRequests] = React.useState<ReindexingRunModel[]>([]);
    const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);
    const [showDetailsModal, setShowDetailsModal] = React.useState<boolean>(false);
    const [selectedRequest, setSelectedRequest] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        getReindexRequests();
    }, []);


    const getReindexRequests = async () => {
        try {
            setIsDataLoaded(false);
            const client = getSensitivityStudiesClient(props.serviceUrl!);
            const resp = await client.getReindexRequests();
            setReindexRequests(resp ?? []);

        } catch (error) {
            console.log("An error occurred while loading reindex requests.", error);
            toast.error("An error occurred while loading requests.");
        }
        finally {
            setIsDataLoaded(true);
        }
    }

    const startReindexing = async () => {
        try {
            const client = getSensitivityStudiesClient(props.serviceUrl!);
            await client.reindexDetectionsInElasticsearch();
            toast.success("Reindexing has started.");
            getReindexRequests();
        } catch (error) {
            console.log("An error occurred while starting reindexing.", error);
            toast.error("An error occurred while starting reindexing.");
        }
    }

    return <>
        <div style={{ padding: "20px" }}>
            <button type="button"
                className="btn btn-sm btn-primary"
                onClick={startReindexing} >
                Start Reindex</button>
            <button type="button"
                className="btn btn-sm btn-secondary ml-4"
                onClick={getReindexRequests} >
                Refresh</button>
        </div>
        <table className="table">
            <thead>
                <tr>
                    <th>Started at:</th>
                    <th>Status</th>
                    <th>Percentage finished</th>
                    <th>Expected duration</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {!isDataLoaded &&
                    <tr>
                        <td colSpan={5} className="text-center">Loading...</td>
                    </tr>
                }
                {isDataLoaded && reindexRequests.map(request => (<tr>
                    <td><DateTimeDisplayUTC date={request.startedAt!} /></td>
                    <td>{getRequestStatus(request.status)}</td>
                    <td>{request.processedPercentage ? `${request.processedPercentage}%` : ""}</td>
                    <td>{request.timeLeftStr}</td>
                    <td><button type="button"
                        className="btn btn-sm btn-secondary"
                        onClick={() => { setSelectedRequest(request.uniqueId); setShowDetailsModal(true); }} >
                        Details</button></td>
                </tr>))}

            </tbody>
        </table>

        {showDetailsModal && <ReindexRequestDetailsModal
            requestId={selectedRequest}
            serviceUrl={props.serviceUrl!}
            show={true}
            onHide={() => { setShowDetailsModal(false); setSelectedRequest(undefined); }} />
        }
    </>

}

export default ReindexRequestsList;