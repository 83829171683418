import React from 'react';


interface IHeaderMobileProps {

}

const HeaderMobile: React.FunctionComponent<IHeaderMobileProps> = (props) => {


    return <div id="kt_header_mobile" className="header-mobile align-items-center  header-mobile-fixed " >

        <a href="/">
            <img alt="Logo" src="assets/media/logos/logo-light.png" />
        </a>



        <div className="d-flex align-items-center">

            <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                <span></span>
            </button>



            <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                <span></span>
            </button>
        </div>

    </div>
}

export default HeaderMobile



