import moment from "moment";


export function _copyAndSortWithSecondaryColumn<T>(items: T[], column1: string, column2: string, isSortedDescending: boolean = false): T[] {
  if(column1==column2){
    return _copyAndSort(items,column1,isSortedDescending);
  }
 
  const firstKey = column1 as keyof T;
  const secondKey = column2 as keyof T;

  if (column1.split('.').length == 2) {
    const splitKey = column1.split('.');
    //@ts-ignore
    return items.slice(0).sort((a: T, b: T) => (compareItemsByMultipleFields(a[splitKey[0]]?.[splitKey[1]], b[splitKey[0]]?.[splitKey[1]],a[secondKey], b[secondKey], isSortedDescending)));
  }

  return items.slice(0).sort((a: T, b: T) => (compareItemsByMultipleFields(a[firstKey], b[firstKey],a[secondKey], b[secondKey], isSortedDescending)));
}

export function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending: boolean = false): T[] {
  const key = columnKey as keyof T;
  // console.log("sorting items:", items);
  //console.log("sorting key:", key);
  if (columnKey.split('.').length == 2) {
    const splitKey = columnKey.split('.');
    //@ts-ignore
    return items.slice(0).sort((a: T, b: T) => (compareItems(a[splitKey[0]]?.[splitKey[1]], b[splitKey[0]]?.[splitKey[1]], isSortedDescending)));
  }
  return items.slice(0).sort((a: T, b: T) => (compareItems(a[key], b[key], isSortedDescending)));
}

function compareItems(a: any, b: any, isSortedDescending: boolean): number {
  // console.log("compareItems:", a, b);
  if (a === null) a = undefined;
  if (b === null) b = undefined;

  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a !== undefined && b === undefined)
    return -1;

  if (a === undefined && b !== undefined)
    return 1;

  if (typeof a == "boolean") {
    return (isSortedDescending ? Number(a) < Number(b) : Number(a) > Number(b)) ? 1 : -1;
  }
  if (/^\d*(\.\d+)?$/.test(a)) {
    //   console.log("sorting numbers", a, b, isSortedDescending);
    return (isSortedDescending ? Number(a) < Number(b) : Number(a) > Number(b)) ? 1 : -1;
  }

  if (moment(a, moment.RFC_2822).isValid()) {
    //   console.log("sorting dates", a, b, isSortedDescending);
    const first = Date.parse(a);
    const second = Date.parse(b);
    return (isSortedDescending ? first < second : first > second) ? 1 : -1;


  }
  else {
    //   console.log("sorting strings", a, b, isSortedDescending);
    return (isSortedDescending ? new String(a).toLowerCase() < new String(b).toLowerCase()
      : new String(a).toLowerCase() > new String(b).toLowerCase()) ? 1 : -1;

  }
}

export const groupBy = (items: any[], key: any) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

function compareItemsByMultipleFields(a1: any, b1: any, a2: any, b2: any, isSortedDescending: boolean): number {
 // console.log("compareItemsByMultipleFields:", a1, b1,a2,b2);
  if (a1 === null) a1 = undefined;
  if (b1 === null) b1 = undefined;

  if (a1 === undefined && b1 === undefined) {
    return 0;
  }
  if (a1 !== undefined && b1 === undefined)
    return -1;

  if (a1 === undefined && b1 !== undefined)
    return 1;

  if (typeof a1 == "boolean" || /^\d*(\.\d+)?$/.test(a1)) {
    if (Number(a1) == Number(b1)) {
      return compareItems(a2, b2, false);
    }
    else {
      return (isSortedDescending ? Number(a1) < Number(b1) : Number(a1) > Number(b1)) ? 1 : -1;
    }

  }

  if (moment(a1, moment.RFC_2822).isValid()) {
    //   console.log("sorting dates", a, b, isSortedDescending);
    const first = Date.parse(a1);
    const second = Date.parse(b1);

    if (first == second) {
      return compareItems(a2, b2, false);
    } else {
      return (isSortedDescending ? first < second : first > second) ? 1 : -1;
    }
  }
  else {

    if (new String(a1).toLowerCase() == new String(b1).toLowerCase()) {
      return compareItems(a2, b2, false);
    } else {
      return (isSortedDescending ? new String(a1).toLowerCase() < new String(b1).toLowerCase()
        : new String(a1).toLowerCase() > new String(b1).toLowerCase()) ? 1 : -1;
    }


  }


}