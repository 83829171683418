import * as React from 'react';
import logo from "../../assets/images/S365_logo_big.png";
import { authenticationService } from "../../services/authentication-service"

const LoginPage: React.FunctionComponent = (props) => {

    const onLoginClick = () => {
        authenticationService.Login();
    }

    return <div className="d-flex w-100 flex-center p-15" style={{ height: "100vh" }}>
        <div className="login-wrapper">

            <div className="text-dark-75 d-flex flex-center align-items-center flex-column">
                <a href="#">
                    <img src={logo} className="max-h-75px" alt="" />
                </a>
                <h1 className="mb-5 mt-5 font-weight-bold">Simulate 365 Admin Panel</h1>                
                <button
                    type="button"
                    id="kt_login_signup"
                    className="btn btn-outline-primary btn-pill py-4 px-9 font-weight-bold"
                    onClick={onLoginClick}
                >Login</button>
            </div>

        </div>
    </div>

        ;
}

export default LoginPage;