import React from "react";

type EmailWithLinkToDomainProps = {
    email?: string;
}

export const EmailWithLinkToDomain: React.FC<EmailWithLinkToDomainProps> = (props) => {

    if (!props.email)
        return null;

    const mailSplit = props.email.split("@");

    const onLinkClick=(ev:React.MouseEvent<HTMLAnchorElement,MouseEvent>)=>{
       
        ev.stopPropagation();
    }

    return <span>{mailSplit[0]}@<a href={`https://www.${mailSplit[1]}`} onClick={(ev)=>onLinkClick(ev)} target="_blank">{mailSplit[1]}</a></span>
}