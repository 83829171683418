import * as React from "react";
import { toast } from "react-toastify";
import { getUserAgentsClient } from "../../../services/api-clients.service";
import { ActiveAgentResponseModel, SimulatorType } from "../../../swagger-clients/dispatcher-next-admin-clients.service";
import TimeAgo from 'react-timeago';
import LoadingService from "../../../components/loading-indicator/loading-indicator.service";


import "bootstrap-icons/font/bootstrap-icons.css";
import cc7Icon from "../../../assets/filetype-icons/cc7.png";
import cc8Icon from "../../../assets/filetype-icons/ccsim.png";
import dwsimIcon from "../../../assets/filetype-icons/dwsim.png";

interface IUserActiveAgentsProps {
    userId: string;
}

interface IAgent {
    id: number;
    displayName: string;
    buildVersion: string;
    simulators: number[];
    instances: ActiveAgentResponseModel[];
    isShared: boolean,
    owner: { id: string, displayName: string }
}


interface IAgentTableItem {
    id: number;
    nameOrInstanceNumber: string;
    buildVersionOrStatus: string;
    lastUpdate: Date;
    currentJobId: number;
    currentJobAssignedAt: Date;
}

const UserActiveAgents: React.FunctionComponent<IUserActiveAgentsProps> = (props) => {

    const [agents, setAgents] = React.useState<IAgent[]>([]);
    const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {

        getUserAgents();
    }, []);

    const getUserAgents = async () => {
        LoadingService.showLoading();
        try {
            setIsDataLoaded(false);
            const client = getUserAgentsClient();
            const instances = await client.getUserAgents(props.userId);

            const agentsResp = getAgentsFromInstances(instances);
            setAgents(agentsResp);
            console.log("Active agents", agentsResp);
        } catch (error) {
            console.log("An error occurred while getting user agents.", error);
            toast.error("An error occurred while getting user agents.");

        } finally {
            LoadingService.hideLoading();
            setIsDataLoaded(true);
        }

    }

    const getAgentsFromInstances = (instances: ActiveAgentResponseModel[]): IAgent[] => {
        let agentsmapped = [] as IAgent[];
        instances.forEach(instance => {

            const instanceAgentId = instance.agentId;
            let instanceAgent = agentsmapped.find((x) => x.id == instanceAgentId);
            if (instanceAgent) {
                instanceAgent.instances.push(instance);
            } else {
                const agent = {
                    id: instance.agentId,
                    displayName: instance.displayName,
                    buildVersion: instance.buildVersion,
                    simulators: instance.simulators,
                    instances: [instance],
                    isShared: instance.isShared,
                    owner: instance.owner
                } as IAgent;
                agentsmapped.push(agent);
            }
        });


        return agentsmapped;
    }


    const getSimulatorTypeInfo = (simulatorType: SimulatorType) => {


        const simulatorTypes = {
            [SimulatorType.Chemcad7]: { name: "Chemcad 7", logo: cc7Icon },
            [SimulatorType.Chemcad8]: { name: "Chemcad 8", logo: cc8Icon },
            [SimulatorType.DWSIM]: { name: "DWSIM", logo: dwsimIcon },
        };

        return simulatorTypes[simulatorType];
    }

    return <table className="table">
        <thead>
            <tr>
                <th>Agent name</th>
                <th>Version / Status</th>
                <th className="text-center">Simulators</th>
                <th>Last updated at</th>
                <th>Current job</th>
                <th>Owner</th>
                <th className="text-center">Shared</th>
            </tr>
        </thead>
        <tbody>
            {agents && agents.length > 0 && <>
                {agents.map(agent => {

                    return <> <tr>
                        <td>{agent.displayName}</td>
                        <td>{agent.buildVersion}</td>
                        <td className="text-center">{agent.simulators.map(simulator => {
                            const simulatorInfo = getSimulatorTypeInfo(simulator);
                            if (!simulatorInfo) {
                                return null;
                            }

                            return <img src={simulatorInfo.logo} alt={simulatorInfo.name} title={simulatorInfo.name} height="20" width="20" />
                        })}</td>
                        <td></td>
                        <td></td>
                        <td>{agent.owner.displayName}</td>
                        <td className="text-center">{agent.isShared ? <i className="bi bi-people-fill"></i> : ""}</td>

                    </tr>
                        {agent.instances.map(instance => {
                            return <tr key={`in_${instance.instanceNumber}`}>
                                <td className="pl-10">Instance #{instance.instanceNumber}</td>
                                <td>{instance.status?.title}</td>
                                <td className="text-center"></td>
                                <td>{instance.lastUpdateAt ? <TimeAgo date={instance.lastUpdateAt} maxPeriod={1} /> : ""}</td>
                                <td>{instance.currentJob ? <span>#{instance.currentJob.jobId} {instance.currentJob ? <span>by <em>{instance.currentJob.owner?.displayName}</em></span> : ""} <br />
                                    <TimeAgo date={instance.currentJob!.assignedAt!} maxPeriod={1} />
                                </span> : ""}</td>
                                <td></td>
                                <td className="text-center"></td>

                            </tr>
                        })}



                    </>
                })}

            </>
            }
            {
                agents.length == 0 && isDataLoaded && <tr className="text-center">
                    <td colSpan={7}>
                        No active agents found.
                    </td>
                </tr>
            }
        </tbody>

    </table>;

}


export default UserActiveAgents