import React from 'react';

const HeaderMenu =()=>{
    return <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
    
    <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
        
        {/* <ul className="menu-nav">
            <li className="menu-item  menu-item-submenu" data-menu-toggle="click" aria-haspopup="true">
                <a href="/" className="menu-link menu-toggle">
                    <span className="menu-text">Pages</span>
                    <i className="menu-arrow"></i>
                </a>
                
            </li>
            <li className="menu-item menu-item-submenu" data-menu-toggle="click" aria-haspopup="true">
                <a href="/" className="menu-link menu-toggle">
                    <span className="menu-text">Features</span>
                    <i className="menu-arrow"></i>
                </a>
                
            </li>
            <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" aria-haspopup="true">
                <a href="/" className="menu-link menu-toggle">
                    <span className="menu-text">Apps</span>
                    <i className="menu-arrow"></i>
                </a>
                
            </li>
        </ul> */}
     
    </div>
 
</div>
}

export default HeaderMenu;