import React from "react";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { GetDwsimProUsagePostModel, UserResultModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import { getDwsimProClient } from "../../services/api-clients.service";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";



type UserLastLoginProps = {
    user?: UserResultModel;

}

export const UserLastLogin: React.FC<UserLastLoginProps> = (props) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [lastVisitedDwsimPro, setLastVisitedDwsimPro] = React.useState<Date>();

    React.useEffect(() => {
        if (!!props.user) {
            getUsersUsageData();
        }
    }, [props.user]);

    const { user } = props;
    if (!user) return null;

    const getUsersUsageData = async () => {

        if (!user) return;

        const client = getDwsimProClient();

        try {
            setIsLoading(true);
            const usernameWithoutDomain: string | undefined = user.userPrincipalName?.split('@')[0];
            const usageData = await client.getDwsimProUsage(new GetDwsimProUsagePostModel({ usernameWithoutDomain: usernameWithoutDomain! }));
            setLastVisitedDwsimPro(usageData?.lastVisit);

        } catch (error) {
            console.log(`An error occurred while loading usage data for user '${user.userPrincipalName}'.`, error);

        } finally {
            setIsLoading(false);
        }
    }




    return <div>
        {!!user.lastLoginAtDashboard && <OverlayTrigger trigger={["hover", "focus"]} placement="top"
            overlay={<ApplicationLoginPopover userId={user.id} name="Dashboard login" lastLoginAt={user.lastLoginAtDashboard} />}>
            <span className="mr-2"><i className="bi bi-folder"></i></span>
        </OverlayTrigger>}
        {!!user.lastLoginAtDWSIM && <OverlayTrigger trigger={["hover", "focus"]} placement="top"
            overlay={<ApplicationLoginPopover userId={user.id} name="DWSIM login" lastLoginAt={user.lastLoginAtDWSIM} />}>
            <span className="mr-2"><i className="bi bi-lightning"></i></span>
        </OverlayTrigger>}
        {!!user.lastLoginAtSimulate365 && <OverlayTrigger trigger={["hover", "focus"]} placement="top"
            overlay={<ApplicationLoginPopover userId={user.id} name="Simulate365.com login" lastLoginAt={user.lastLoginAtSimulate365} />}>
            <span className="mr-2"><i className="bi bi-globe"></i></span>
        </OverlayTrigger>}
        {!!user.lastLoginAtVM && <OverlayTrigger trigger={["hover", "focus"]} placement="top"
            overlay={(overlayProps) => <ApplicationLoginPopover userId={user.id} name="VM Portal login" lastLoginAt={user.lastLoginAtVM} {...overlayProps} />}>
            <span className="mr-2"><i className="bi bi-pc"></i></span>
        </OverlayTrigger>}

        {isLoading && <Spinner className="mr-2" animation={"border"} size="sm" />}
        {!isLoading && lastVisitedDwsimPro && <OverlayTrigger trigger={["hover", "focus"]} placement="top"
            overlay={(overlayProps) => <ApplicationLoginPopover userId={user.id} name="DWSIM PRO" lastLoginAt={lastVisitedDwsimPro}  {...overlayProps} />}>
            <span className="mr-2"><i className="bi bi-lightbulb"></i></span>
        </OverlayTrigger>}

    </div>
}




export const ApplicationLoginPopover = React.forwardRef((props: any, ref: any) => {
    return <Popover id="popover-positioned-top" ref={ref} {...props}>

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "5px" }}>
            <span>{props.name}</span>
            {props.lastLoginAt && <span><DateTimeDisplayUTC dateFormat='DD-MM-YYYY' date={props.lastLoginAt} hideTime /></span>}
        </div>

    </Popover>
});

