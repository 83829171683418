import * as React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { clearInterval } from "timers";
import { getSensitivityStudiesClient } from "../../../services/api-clients.service";
import { ReindexingRunModel, RunningStatus } from "../../../swagger-clients/sensitivity-studies-clients.service";
import { DateTimeDisplayUTC } from "../../../components/date-time-display/date-time-display.component";


interface IReindexRequestDetailsModalProps {
    serviceUrl: string;
    requestId?: string;
    show: boolean;
    onHide: () => void;
}



const ReindexRequestDetailsModal: React.FunctionComponent<IReindexRequestDetailsModalProps> = (props) => {

    const [request, setRequest] = React.useState<ReindexingRunModel | undefined>(undefined);

    React.useEffect(() => {
        getRequestDetails();
        const interval = setInterval(() => { getRequestDetails() }, 5000);
        return () => window.clearInterval(interval);
    }, []);

    const getRequestDetails = async () => {
        try {
            const client = getSensitivityStudiesClient(props.serviceUrl);
            const resp = await client.getReindexRequest(props.requestId!);
            setRequest(resp);
        } catch (error) {
            console.log("An error occurred while loading reindex request details.", error);
            toast.error("An error occurred while loading request details.");
        }

    }




    return <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
        size="lg"
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                Reindexing status
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {request ? <><div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: request.processedPercentage + "%" }}>{request.processedPercentage}%</div>
            </div>
                <dl className="row">
                    <dt className="col-sm-6 text-right">Time left</dt>
                    <dd className="col-sm-6">{request.timeLeftStr}</dd>
                    <dt className="col-sm-6 text-right">Started at</dt>
                    <dd className="col-sm-6"><DateTimeDisplayUTC date={request.startedAt!}/></dd>
                    <dt className="col-sm-6 text-right">Status</dt>
                    <dd className="col-sm-6">{getRequestStatus(request.status)}</dd>
                    <dt className="col-sm-6 text-right">Type</dt>
                    <dd className="col-sm-6">{request.type}</dd>
                    <dt className="col-sm-6 text-right">Batch size</dt>
                    <dd className="col-sm-6">{request.batchSize}</dd>
                    <dt className="col-sm-6 text-right">Total items</dt>
                    <dd className="col-sm-6">{request.totalItemsCount}</dd>
                    <dt className="col-sm-6 text-right">Processed items</dt>
                    <dd className="col-sm-6">{request.processedItemsCount}</dd>
                    <dt className="col-sm-6 text-right">Last Processed Id</dt>
                    <dd className="col-sm-6">{request.lastProcessedId}</dd>
                    <dt className="col-sm-6 text-right">Overall average duration per iteration</dt>
                    <dd className="col-sm-6">{request.overallAverageDurationPerIteration} ms</dd>
                    <dt className="col-sm-6 text-right">Sql average duration per iteration</dt>
                    <dd className="col-sm-6">{request.sqlAverageDurationPerIteration} ms</dd>
                    <dt className="col-sm-6 text-right">Index average duration per iteration</dt>
                    <dd className="col-sm-6">{request.indexAverageDurationPerIteration} ms</dd>
                </dl>
                {request.message &&
                    <div className="alert alert-info" role="alert">
                        {request.message}
                    </div>
                }
            </> : null
            }

        </Modal.Body>

        <Modal.Footer>
            <div className="form-group d-flex float-right">
                <button type="button" onClick={props.onHide} className={'btn btn-info ml-2'}>
                    {"Close"}
                </button>
            </div>
        </Modal.Footer>

    </Modal>
}
export const getRequestStatus = (status?: RunningStatus) => {
    switch (status) {
        case RunningStatus.Failed:
            return "Failed";
        case RunningStatus.Running:
            return "Running";

        case RunningStatus.Finished:
            return "Finished";
        default:
            return "";
    }

}

export default ReindexRequestDetailsModal;