import * as React from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { JsxElement } from "typescript";
import { LoadingService } from "../../components/loading-indicator/loading-indicator.component";
import ContentWrapper from "../../layout/components/content-wrapper/content-wrapper.component";
import SubHeader from "../../layout/components/sub-header/sub-header.component";
import { getUserAgentsClient } from "../../services/api-clients.service";
import { UserAgentsResponseModel } from "../../swagger-clients/dispatcher-next-admin-clients.service";
import UserDetailsModal from "../users/user-details-modal/user-details-modal.component";
import SimulatorCountComponent from "./simulator-count.component";

interface IDispatcherUsageProps {

}

interface IDispatcherUsageState {
    userAgents: UserAgentsResponseModel[];
    showDetailsModal: boolean;
    selectedUserId: string | undefined;
}


class DispatcherUsage extends React.Component<IDispatcherUsageProps, IDispatcherUsageState>{


    constructor(props: IDispatcherUsageProps) {
        super(props);
        this.state = { userAgents: [], showDetailsModal: false, selectedUserId: undefined };
    }

    async componentDidMount() {
        this.getUsageData();
    }
    async getUsageData() {
        try {
            LoadingService.showLoading();
            const client = getUserAgentsClient();
            var userAgents = await client.getUserAgentsList();
            this.setState({ userAgents: userAgents });
        } catch (error) {
            console.log("An error occurred while getting usage data.", error);
            toast.error("An error occurred while getting usage data.");
        } finally {
            LoadingService.hideLoading();
        }
    }


    render() {
        const { userAgents, showDetailsModal, selectedUserId } = this.state;

        let rightItems: any[] = [
            <button type="button"
                className="btn btn-primary"                
                onClick={this.getUsageData.bind(this)}>
                <i className="fas fa-sync" /> Refresh </button>
        ];

        return [<SubHeader key="sub-header" title="Dispatcher usage" leftItems={[]} rightItems={rightItems} />,
        <ContentWrapper key="content-wrapper">
            <Card style={{ width: "1200px", marginRight: "auto", marginLeft: "auto" }}>
                <Card.Body>
                    <table className="table table-vertical-middle table-striped table-hover">
                        <thead>
                            <tr>

                                <th>Username</th>
                                <th className="text-center" >Uncalculated jobs</th>
                                <th className="text-center">Calculating jobs</th>
                                <th className="text-center">Available agents</th>
                                <th className="text-center">Personal agents</th>
                            </tr>
                        </thead>
                        <tbody>

                            {userAgents.map((userAgent) => (<tr>
                                <td>
                                    <a href="#" onClick={() => this.setState({ showDetailsModal: true, selectedUserId: userAgent.user?.id })}>
                                        {userAgent.user?.displayName}</a>
                                    <br />
                                    {userAgent.user?.userPrincipalName}</td>
                                <td className="text-center">
                                    <SimulatorCountComponent count={userAgent.uncalculatedJobs} />
                                </td>
                                <td className="text-center">
                                    <SimulatorCountComponent count={userAgent.calculatingJobs} />
                                </td>
                                <td className="text-center">
                                    <SimulatorCountComponent count={userAgent.availableAgents} />

                                </td>
                                <td className="text-center">
                                    <SimulatorCountComponent count={userAgent.personalAgents} />
                                </td>

                            </tr>))
                            }

                        </tbody>
                    </table>
                    {showDetailsModal && <UserDetailsModal
                        show={true}
                        userId={selectedUserId ?? ""}
                        onHide={() => this.setState({ showDetailsModal: false, selectedUserId: undefined })} />
                    }

                </Card.Body>
            </Card>
        </ContentWrapper>];;
    }

}

export default DispatcherUsage;