import React from "react";
import { Modal } from "react-bootstrap";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { IMotherTicketPostModel, MotherTicketPostModel, UserResultModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import { getMotherTicketClient } from "../../services/api-clients.service";

interface ICreateMotherTicketModalProps {

    show: boolean;
    user: UserResultModel;
    onSuccess: (motherTicketId: number) => void;
    onHide: () => void;
}

const CreateMotherTicketModal: React.FunctionComponent<ICreateMotherTicketModalProps> = (props) => {


    const { register, handleSubmit, formState, setValue } = useForm<IMotherTicketPostModel>();
    // submit request 
    const [isRequestInProgress, setIsRequestInProgress] = React.useState<boolean>(false);

    React.useEffect(() => {

        if (props.user) {
            const userDomainSplit = props.user.email?.split('@');
            if (userDomainSplit?.length == 2) {
                setValue("domain1", userDomainSplit[1]);
                setValue("ticketName", `${userDomainSplit[1]} created from Admin Panel`);
            }
        }

    }, []);





    const onSubmit = async (data: IMotherTicketPostModel) => {

        try {
            setIsRequestInProgress(true);
            const client = getMotherTicketClient();
            const resp = await client.createMotherTicket(new MotherTicketPostModel(data));

            toast.success("Mother ticket was successfully created.");
            props.onSuccess(resp.targetProcessTicketId);
        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while creating mother ticket.");
        }
        finally {
            setIsRequestInProgress(false);
        }
    }




    return <Modal
        size="lg"
        show={props.show}
        onHide={props.onHide}
        animation={false}
    >
        <Modal.Header closeButton={!isRequestInProgress ? true : false}>

            <Modal.Title>
                Create Mother Ticket
            </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>

                <div className="form-group  row align-items-center mb-2">
                    <label className="form-label col-sm-3">Ticket name</label>
                    <div className="col-sm-9">
                        <input
                            className="form-control form-control-sm"
                            style={{ marginBottom: "5px" }}
                            type="text"
                            placeholder="Name"
                            autoComplete={"false"}
                            {...register("ticketName", { required: true })}
                        />
                        {formState.errors && formState.errors.ticketName && (<p className="text-danger">Ticket name is required.</p>)}
                    </div>

                </div>


                <div className="form-group  row align-items-center mb-2">
                    <label className="form-label col-sm-3">Domain 1</label>
                    <div className="col-sm-9">
                        <input
                            className="form-control form-control-sm"
                            style={{ marginBottom: "5px" }}
                            type="text"
                            placeholder="Domain 1"
                            autoComplete={"false"}
                            {...register("domain1", { required: true })}
                        />
                        {formState.errors && formState.errors.domain1 && (<p className="text-danger">Domain 1 is required!</p>)}
                    </div>
                </div>

                <div className="form-group  row align-items-center mb-2">
                    <label className="form-label col-sm-3">Domain 2</label>
                    <div className="col-sm-9">
                        <input
                            className="form-control form-control-sm"
                            style={{ marginBottom: "5px" }}
                            type="text"
                            placeholder="Domain 2"
                            autoComplete={"false"}
                            {...register("domain2")}
                        />
                    </div>
                </div>
                <div className="form-group  row align-items-center mb-2">
                    <label className="form-label col-sm-3">Domain 3</label>
                    <div className="col-sm-9">
                        <input
                            className="form-control form-control-sm"
                            style={{ marginBottom: "5px" }}
                            type="text"
                            placeholder="Domain 3"
                            autoComplete={"false"}
                            {...register("domain3")}
                        />
                    </div>
                </div>

                <div className="form-group  row align-items-center mb-2">
                    <label className="form-label col-sm-3">Domain 4</label>
                    <div className="col-sm-9">
                        <input
                            className="form-control form-control-sm"
                            style={{ marginBottom: "5px" }}
                            type="text"
                            placeholder="Domain 4"
                            autoComplete={"false"}
                            {...register("domain4")}
                        />
                    </div>
                </div>
                <div className="form-group  row align-items-center mb-2">
                    <label className="form-label col-sm-3">Domain 5</label>
                    <div className="col-sm-9">
                        <input
                            className="form-control form-control-sm"
                            style={{ marginBottom: "5px" }}
                            type="text"
                            placeholder="Domain 5"
                            autoComplete={"false"}
                            {...register("domain5")}
                        />
                    </div>
                </div>






            </Modal.Body>
            <Modal.Footer>
                <div className="form-group d-flex float-right">
                    <button type="submit" className={'btn btn-primary'} disabled={isRequestInProgress}>
                        {isRequestInProgress && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
                        Create
                    </button>
                    <button type="button" onClick={props.onHide} className={'btn btn-danger ml-2'} disabled={isRequestInProgress}>
                        {"Cancel"}
                    </button>

                </div>
            </Modal.Footer>

        </form>
    </Modal>


}

export default CreateMotherTicketModal;