import React from "react";
import { IPagination, UtcTimePopover } from "../screenshots/screenshots.component";
import LoadingService from "../../components/loading-indicator/loading-indicator.service";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import { getTelemetryFeedbacksClient } from "../../services/api-clients.service";
import { FeedbackResponseModel } from "../../swagger-clients/telemetry-clients.service";
import SubHeader from "../../layout/components/sub-header/sub-header.component";
import ContentWrapper from "../../layout/components/content-wrapper/content-wrapper.component";
import { Card, Dropdown, Form, OverlayTrigger } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import { addDays } from "../../utilities/date.utilities";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";

type FeebacksPageProps = {

}

const getTodayDate = () => {
    let dateNow = new Date();
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    dateNow.setMilliseconds(0);
    return dateNow;
}

const getDefaultStartDate = () => {

    return addDays(getTodayDate(), -30);
}


const getDefaultEndDate = () => {
    let tomorrow = addDays(new Date(), 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    return tomorrow;

}


export const FeebacksPage: React.FC<FeebacksPageProps> = (props) => {

    const [feedbacks, setFeedbacks] = React.useState<FeedbackResponseModel[]>([]);
    const [pagination, setPagination] = React.useState<IPagination>({
        currentPage: 1,
        recordsPerPage: 20,
        totalRecords: 0
    });
    const [fromDate, setFromDate] = React.useState<Date>(getDefaultStartDate());
    const [toDate, setToDate] = React.useState<Date>(getDefaultEndDate());
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>();
    const [selectedFeedbackId, setSelectedFeedbackId] = React.useState<number>();
    const [npsScore, setNpsScore] = React.useState<number>();
    const [hideTestUsers, setHideTestUsers] = React.useState<boolean>(true);

    React.useEffect(() => {
        getFeedbacks();
    }, []);
    React.useEffect(() => {
        getFeedbacks();
    }, [pagination.currentPage, pagination.recordsPerPage]);

    const getFeedbacks = async () => {
        try {
            setFeedbacks([]);
            setIsLoading(true);

            const { recordsPerPage, currentPage } = pagination;
            const client = getTelemetryFeedbacksClient();
            const startDateUtc = fromDate ? new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDate.getHours(), fromDate.getMinutes(), fromDate.getSeconds())) : null;
            const endDateUtc = toDate ? new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDate.getHours(), toDate.getMinutes(), toDate.getSeconds())) : undefined;
            const feedbacksResponse = await client.getFeedbacks(searchTerm, startDateUtc, endDateUtc, hideTestUsers, (currentPage - 1) * recordsPerPage, recordsPerPage);
            setFeedbacks(feedbacksResponse?.records ?? []);
            setPagination(s => ({ ...s, totalRecords: feedbacksResponse?.totalRecords ?? 0 }));
            setNpsScore(feedbacksResponse?.npsScore);

        } catch (error) {
            ShowErrorFromResponse(error, "An error ocurred while getting feedbacks.");
        } finally {
            setIsLoading(false);
        }
    }


    const onTodayClick = () => {
        setFromDate(getTodayDate());
        setToDate(getDefaultEndDate());

    }
    const onLast7DaysClick = () => {
        const startDate = addDays(getTodayDate(), -7);
        setFromDate(startDate);
        setToDate(getDefaultEndDate());

    }
    const onLast30DaysClick = () => {
        const startDate = addDays(getTodayDate(), -30);
        setFromDate(startDate);
        setToDate(getDefaultEndDate());

    }

    const onLastMonthClick = () => {
        const dateNow = new Date();
        const firstInMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        let firstInNextMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        firstInNextMonth.setMonth(firstInMonth.getMonth() + 1);
        const lastDayOfMonth = addDays(firstInNextMonth, -1);
        setFromDate(firstInMonth);
        setToDate(lastDayOfMonth);

    }

    const onLastYearClick = () => {
        const dateNow = new Date();
        const januaryFirst = new Date(dateNow.getFullYear(), 0, 1, 0, 0, 0);
        setFromDate(januaryFirst);
        setToDate(getDefaultEndDate());

    }

    const onAddDayClick = (numberOfDays: number) => {

        const newDate = addDays(fromDate ?? new Date(), numberOfDays);
        setFromDate(newDate);
    }
    const onAddMonthClick = (numberOfMonths: number) => {
        let newDate = !!fromDate ? new Date(fromDate) : new Date();
        newDate.setMonth(newDate.getMonth() + numberOfMonths);
        setFromDate(newDate);
    }


    const { totalRecords, currentPage, recordsPerPage } = pagination;
    const totalPages = Math.ceil(totalRecords / recordsPerPage);

    return <div> <SubHeader key="sub-header" title="Feedbacks" leftItems={[]} rightItems={[]} />
        <ContentWrapper key="content-wrapper" className="pl-4 pr-4">
            <Card className='mb-4' style={{ marginRight: "auto", marginLeft: "auto", width: "100%" }}>
                <div className="pt-3 pl-3 pr-3 mr-3 d-flex">
                    <form onSubmit={(ev) => { ev.preventDefault(); if (currentPage !== 1) { setPagination(s => ({ ...s, currentPage: 1 })); } else { getFeedbacks(); } }} action="">
                        <div className="form-row align-items-center">
                            <div className="col-4 input-group mr-2 mb-2" style={{ minWidth: "330px" }}>
                                <span className="input-group-text"><i className="far fa-user"></i></span>
                                <input type="text"
                                    name="searchTerm"
                                    className="form-control"
                                    onChange={(ev) => { const value = ev.target.value.toString(); setSearchTerm(value); }}
                                    placeholder="Username" value={searchTerm}
                                />
                            </div>
                            <div className="input-group mr-2 mb-3" style={{ width: '380px' }}>
                                <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
                                <div className="input-group__datepicker-wrapper">
                                    <DatePicker
                                        id="fromDate"
                                        className="form-control"
                                        selected={fromDate}
                                        onChange={date => {
                                            if (!date) {
                                                setFromDate(getDefaultStartDate());
                                            } else
                                                setFromDate(new Date(Date.parse(date != null ? date.toString() : "")));
                                        }}
                                        showTimeSelect
                                        maxDate={toDate}
                                        dateFormat="dd-MM-yyyy HH:mm"
                                        timeFormat="HH:mm" />
                                </div>
                                <span className="input-group-text">-</span>

                                <div className="input-group__datepicker-wrapper">
                                    <DatePicker
                                        id="toDate"
                                        className="form-control"
                                        selected={toDate}
                                        onChange={date => {
                                            if (!date) {
                                                setToDate(getDefaultEndDate());
                                            } else
                                                setToDate(new Date(Date.parse(date != null ? date.toString() : "")))
                                        }}
                                        showTimeSelect
                                        maxDate={new Date()}
                                        dateFormat="dd-MM-yyyy HH:mm"
                                        timeFormat="HH:mm" />
                                </div>
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" className="datePickerWithDropdown" id="dropdown-basic" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onTodayClick} >Today</Dropdown.Item>
                                        <Dropdown.Item onClick={onLast7DaysClick}>Last 7 days</Dropdown.Item>
                                        <Dropdown.Item onClick={onLast30DaysClick}>Last 30 days</Dropdown.Item>
                                        <Dropdown.Item onClick={onLastMonthClick}>This month</Dropdown.Item>
                                        <Dropdown.Item onClick={onLastYearClick}>This year</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onAddDayClick(1) }}>+ day</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onAddDayClick(-1) }}>- day</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onAddMonthClick(1) }}>+ month</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onAddMonthClick(-1) }}>- month</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>


                            </div>

                            <div className="col-auto mr-2">
                                <Form.Check type="checkbox"
                                    checked={hideTestUsers}
                                    onChange={(ev: any) => { setHideTestUsers(ev.target.checked); }}
                                    label="Hide test users" />
                            </div>


                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary mb-2" >Search</button></div>
                        </div>
                    </form>


                    <div className="mr-3" style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", marginRight: "8px" }}>
                            <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{npsScore ?? "-"}</b>
                            <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>NPS score</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                            <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{totalRecords}</b>
                            <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>Total records</span>
                        </div>

                    </div>
                    <div>
                        <select className="form-control form-select" value={recordsPerPage} onChange={(event) => setPagination(s => ({ ...s, recordsPerPage: +event.target.value }))}>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="10000">1000</option>
                        </select>
                    </div>

                </div>
            </Card>
            <Card >
                <Card.Body>

                    <div className="">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="" style={{ width: "100px" }}>Id</th>
                                    <th className="">Username</th>
                                    <th className=" text-center" style={{ width: "100px" }}>Score</th>
                                    <th className=" text-center" style={{ width: "500px" }}>Comment</th>
                                    <th className=" text-center">Created</th>
                                </tr>
                            </thead>
                            <tbody>

                                {feedbacks.map((item) =>
                                (<tr key={item.id} onClick={() => setSelectedFeedbackId(item.id)}>
                                    <td className="" style={{ width: "100px" }}>{item.id}</td>
                                    <td className="">{item.user}</td>
                                    <td className="text-center " style={{ width: "100px" }}>{item.score}</td>
                                    <td className={`text-center ${selectedFeedbackId !== item.id ? "text-one-line-ellipsis" : ""}`}
                                        style={{ maxWidth: "500px" }}>
                                        {item.comment}</td>
                                    <td className="text-center ">
                                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={UtcTimePopover}>
                                            <span><DateTimeDisplayUTC date={item.createdAtUtc!} /></span>
                                        </OverlayTrigger></td>

                                </tr>))
                                }

                                {!!isLoading &&
                                    <tr>
                                        <td colSpan={5} className="text-center">Loading...</td>
                                    </tr>
                                }


                            </tbody>
                        </table>

                        {totalPages > 1 &&
                            <div className="d-flex align-items-center">
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me page-item'}
                                    breakLinkClassName={'page-link'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    pageCount={totalPages}
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={10}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    forcePage={currentPage - 1}
                                    onPageChange={(page: any) => {
                                        console.log("Selected page:", page);
                                        setPagination(s => ({ ...s, currentPage: page.selected + 1 }));
                                        document.body.scrollTop = 0; // For Safari
                                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                    }}
                                    containerClassName={'pagination mb-0'}
                                    activeClassName={'active'}
                                />

                            </div>
                        }
                    </div>



                </Card.Body>
            </Card>
        </ContentWrapper></div>;
}