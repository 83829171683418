import * as  React from 'react';
import { Modal } from "react-bootstrap"
import { toast } from 'react-toastify';
import LoadingService from '../../../components/loading-indicator/loading-indicator.service';
import { getPanelOrganizationsClient } from "../../../services/api-clients.service";
import { SyncStatusMessage } from '../../../swagger-clients/s365-admin-panel-clients.service';
import {useInterval} from "../../../hooks/useInterval";
import { DateTimeDisplayUTC } from '../../../components/date-time-display/date-time-display.component';

interface ISyncUsersModalProps {
    show: boolean;
    onHide: () => void;
}

const SyncUsersModal: React.FunctionComponent<ISyncUsersModalProps> = (props) => {

    const [isSyncInProgress, setIsSyncInProgress] = React.useState<boolean>(false);
    const [messages, setMessages] = React.useState<SyncStatusMessage[]>([]);
    const [processedUsers,setProcessedUsers]=React.useState<number>(0);
    const [totalUsers,setTotalUsers]=React.useState<number>(0);

React.useEffect(()=>{
    getStatus();
},[]);

    useInterval(()=>{
        getStatus();
    },5000);

    const getStatus = async () => {
        try {
            const client = getPanelOrganizationsClient();
            const resp = await client.getSyncUsersStatus();
            if (resp) {
                setIsSyncInProgress(resp.isActive);
                setMessages(resp.messages ?? []);
                setTotalUsers(resp.totalUsers);
                setProcessedUsers(resp.processedUsers);
            }
        } catch (error) {
            console.log("An error occurred while getting sync status.");
        }
    }

    const onSyncUsersClick = async () => {
        try {
            LoadingService.showLoading();
            var client = getPanelOrganizationsClient();
            await client.updateOrganizationUsers();
            setIsSyncInProgress(true);
            getStatus();
            toast.info("Syncing users in organizations has started.");
        } catch (error) {
            console.log("An error occurred while trying to sync users.", error);
            toast.error("An error occurred while trying to sync users with organizations.");
        } finally {
            LoadingService.hideLoading();
        }
    }

    return <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
        size="lg"
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                Sync users with organizations
            </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: "15px 25px" }}>
            <dl className="row d-flex align-items-center" style={{padding:"0px 15px"}}>
                <dd className="col-1 p-0 m-0">Status</dd>
                <dt className="col-11"> {isSyncInProgress ?
                    <div style={{ display: "flex", alignItems: "center" }}><span className="text-danger" style={{ fontSize: "20px", margin: "0px 5px" }}>&bull;</span> <span> In progress</span></div>
                    : <div style={{ display: "flex", alignItems: "center" }}><span className="text-success" style={{ fontSize: "20px", margin: "0px 5px" }}>&bull;</span><span>Finished</span></div>
                }</dt>
                 <dd className="col-1 p-0 m-0">Processed</dd>
                 <dt className="col-11">{processedUsers} of {totalUsers} Users</dt>

            </dl>           

            <div style={{maxHeight:"70vh", overflowY:"auto"}}>
                <p>Messages:</p>
                <ul style={{listStyle:"none", paddingLeft:"10px"}}>
                    {
                     messages.length>0 ?    messages.map((message) => {
                            return <li key={message.statusAt.toString()}><b><DateTimeDisplayUTC date={message.statusAt}/></b> {message.message}</li>;
                        })
                        : "There are no previous messages."
                    }

                </ul>
            </div>

        </Modal.Body>

        <Modal.Footer>
            <div className="form-group d-flex float-right">
                <button type="button" onClick={onSyncUsersClick} className={'btn btn-danger ml-2'} disabled={isSyncInProgress}>
                    Start Sync
                </button>
                <button type="button" onClick={props.onHide} className={'btn btn-info ml-2'}>
                    Close
                </button>
            </div>
        </Modal.Footer>

    </Modal>
}

export default SyncUsersModal