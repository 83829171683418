import * as React from "react";

interface ITableHeaderItemProps {
    title: string;
    isSorted?: boolean;
    sortDescending?: boolean;
    onClick?():void;

}

export const TableHeaderItem: React.FunctionComponent<ITableHeaderItemProps> = (props) => {

    const { title, isSorted, sortDescending } = props;
    return <th scope="col">
        <div onClick={props.onClick}
            style={{ userSelect: "none", cursor: "pointer", maxWidth: "150px" }}>
            {props.title}
            {isSorted && sortDescending && <span className="fas fa-sort-alpha-down-alt" style={{ paddingLeft: "5px" }}></span>}
            {isSorted && !sortDescending && <span className="fas fa-sort-alpha-up" style={{ paddingLeft: "5px" }}></span>}
        </div>
    </th>
}