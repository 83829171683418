


class SidebarPlaceHolderService {

    private static _callbackFunctions: any[] = [];

    static setContent(element?: JSX.Element) {
        if (SidebarPlaceHolderService._callbackFunctions && SidebarPlaceHolderService._callbackFunctions.length > 0) {
            for (let f of SidebarPlaceHolderService._callbackFunctions) {
                f(element);
            }
        }
    }

    static addCallback(callbackFunc: any) {
        SidebarPlaceHolderService._callbackFunctions.push(callbackFunc);
    }
}

export default SidebarPlaceHolderService;