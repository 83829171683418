import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getResetPasswordClientClient } from '../../services/api-clients.service';
import { ErrorResultModel, SetPasswordForUserModel } from '../../swagger-clients/dashboard-clients.service';
import * as passwordGenerator from "generate-password";

interface IResetPaswordModalProps {
    userId: string;
    userDisplayName: string;
    show: boolean;
    onHide: () => void;
}

interface IResetPasswordModel {
    password: string;
    passwordConfirm: string;
}

export const ResetPasswordModal: React.FC<IResetPaswordModalProps> = (props) => {

    const [showGeneratedPassword, SetShowGeneratedPassword] = React.useState<boolean>(false);
    const [requestInProgress, setRequestInprogress] = React.useState<boolean>(false);
    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required'),
        passwordConfirm: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password')], 'Passwords must and should match'),
    });

    const validationOpt = { resolver: yupResolver(formSchema) };

    const { control, register, handleSubmit, formState, getValues, setValue, watch } = useForm<IResetPasswordModel>(validationOpt);
    const watchFields = watch(["password", "passwordConfirm"]);
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => SetShowGeneratedPassword(false));
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit = async (data: IResetPasswordModel) => {

        try {
            setRequestInprogress(true);
            const client = getResetPasswordClientClient();
            await client.setPasswordForUser({ password: data.password, userId: props.userId } as SetPasswordForUserModel);
            toast.success("Password changed.");
            //props.onHide();

        } catch (error) {
            console.log("An error occurred while reseting password.", error);
            const errorResultModel = error as ErrorResultModel;
            if(errorResultModel.errors && errorResultModel.errors.length>0){
               toast.error(errorResultModel.errors[0]);
            }else
            if (errorResultModel.validationErrors && errorResultModel.validationErrors.Password) {
                toast.error(errorResultModel.validationErrors.Password[0]);
            } else {
                toast.error("An error occurred while reseting password.");
            }
        } finally {
            setRequestInprogress(false);
        }
    }
    const onCopyClick = () => {
        navigator.clipboard.writeText(password);
        toast.success("Password copied to clipboard.");
    }

    const onGeneratePasswordClick = () => {
        const generatedPassword = passwordGenerator.generate({
            length: 10,
            numbers: true,
            uppercase: true,
            symbols: true,
            strict: true
        });

        setValue("password", generatedPassword, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
        setValue("passwordConfirm", generatedPassword, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
        SetShowGeneratedPassword(true);
    }

    const { errors } = formState;
    const { password } = getValues();

    return <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
    >
        <form className="form" id="kt_login_signin_form" onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton={true}>

                <Modal.Title>
                    Reset Password for user "{props.userDisplayName}"
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {!showGeneratedPassword &&
                    <>  <div className='form-group row align-items-center'>

                        <label className='col-4'>Password</label>
                        <div className='col-8'>
                            <input type="password" className='form-control'  {...register("password", { required: true })} />
                            {errors.password?.message && (<p className="text-danger">{errors.password?.message}</p>)}
                        </div>
                    </div>
                        <div className='form-group row align-items-center'>
                            <label className='col-4'>Confirm password</label>
                            <div className='col-8'>
                                <input type="password" className='form-control' {...register("passwordConfirm", { required: true })} />
                                {errors.passwordConfirm?.message && (<p className="text-danger">{errors.passwordConfirm?.message}</p>)}
                            </div>
                        </div>
                    </>
                }
                {showGeneratedPassword && <div className='form-group row align-items-center'>
                    <label className='col-4'>Generated Password</label>
                    <div className='col-8 d-flex flex-row align-items-center'>
                        <span><b>{password}</b></span>
                        <button type="button" className='btn btn-success ml-4' onClick={() => onCopyClick()}>Copy</button>
                    </div>
                </div>}

            </Modal.Body>

            <Modal.Footer>
                <div className="d-flex" style={{ width: "100%", justifyContent: "space-between" }}>

                    <div>
                        <button type="button" className={'btn btn-primary'} disabled={requestInProgress} onClick={onGeneratePasswordClick}>
                            {"Generate password"}
                        </button>
                    </div>

                    <div>
                        <button type="submit" className={'btn btn-primary ml-2'} disabled={requestInProgress}>
                            {"Reset"}
                        </button>

                        <button type="button" onClick={props.onHide} className={'btn btn-secondary ml-2'}>
                            {"Close"}
                        </button>
                    </div>

                </div>
            </Modal.Footer>
        </form>
    </Modal>
}