import React from "react";
import targetProcessLogo from "../../assets/images/target-process-logo.png";
import { Button } from "react-bootstrap";

type CreateMotherTicketButtonProps = {
    className?: string;
    onClick: () => void;
}

export const CreateMotherTicketButton: React.FC<CreateMotherTicketButtonProps> = (props) => {


    return <Button
        variant="link"
        className={props.className}
        onClick={(ev) => { ev.stopPropagation(); props.onClick(); }}
        style={{ marginLeft: "20px" }}
    >
        <img height={"20px"} src={targetProcessLogo} alt="Target process logo" />
        <span className="ml-2">Create Ticket</span>
    </Button>

}