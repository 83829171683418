import { UsersClient } from '../swagger-clients/s365-admin-panel-clients.service';



const login = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/external-login`;

}

const getCurrentUser = async () => {
   
        const client = new UsersClient(process.env.REACT_APP_BASE_URL);
        const response = await client.getCurrentUser();
        return response;

   
}





export const authenticationService = ({
    Login: login,
    GetCurrentUser: getCurrentUser


})