import * as React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import LoadingService from "../../../components/loading-indicator/loading-indicator.service";
import { getOrganizationsClient } from "../../../services/api-clients.service";
import { ErrorResultModel, OrganizationPostModel, OrganizationsClient } from "../../../swagger-clients/dashboard-clients.service";
import InputList from "./input-list/input-list-component";

interface IEditOrganizationModalProps {
    organizationId?: number;
    show: boolean;
    onOrganizationChanged: () => void;
    onHide: () => void;
}


const EditOrganizationModal: React.FunctionComponent<IEditOrganizationModalProps> = (props) => {


    const { control, register, handleSubmit, formState, getValues, setValue } = useForm<OrganizationPostModel>();
    const [isLoading, SetIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {

        if (props.organizationId) {
            getOrganization(props.organizationId);
        }

    }, [props.organizationId]);


    const getOrganization = async (orgId: number) => {
        try {
            LoadingService.showLoading();
            const client = getOrganizationsClient();
            const organization = await client.getOrganization(orgId);
            if (organization) {
                setValue('name', organization.name ?? "");
                setValue('azureGroupName', organization.azureGroupName ?? "");
                setValue('rmsServer', organization.rmsServer ?? "");
                setValue('domains', organization.domains ?? []);

            }


        } catch (error) {
            console.log("An error occurred while getting organization.", error);
            toast.error("An error occurred while getting organization.");
        }finally{
            LoadingService.hideLoading();
        }
    }

    const onSubmit = async (data: OrganizationPostModel) => {

        try {
            SetIsLoading(true);
            data.id = props.organizationId ? props.organizationId : 0;
            const client = getOrganizationsClient();
            await client.createOrUpdateOrganization(data);
            SetIsLoading(false);
            props.onOrganizationChanged();
            toast.success(`Organization successfully ${data.id && data.id > 0 ? "updated" : "created"}.`);

        }
        catch (error) {
            console.log("An error occured while saving organization.");
            const errorResultModel = error as ErrorResultModel;
            if (errorResultModel.errors && errorResultModel.errors.length > 0) {
                toast.error(errorResultModel.errors[0]);
            } else {
                toast.error("An error occured while saving organization.");
            }

            SetIsLoading(false);
        }
    }

    const isEdit = props.organizationId && props.organizationId > 0;


    return <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                {isEdit ? "Edit" : "Create"} Organization
            </Modal.Title>
        </Modal.Header>
        <form className="form" id="kt_login_signin_form" onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>

                <div className="form-group mb-5">
                    <label className="form-label">Name</label>
                    <input
                        className="form-control h-auto form-control-solid py-4 px-8"
                        style={{ marginBottom: "5px" }}
                        type="text"
                        placeholder="Name"
                        autoComplete={"false"}
                        {...register("name", { required: true })}
                    />
                    {formState.errors && formState.errors.name && formState.errors.name.type === "required" && (<p className="text-danger">Value is required!</p>)}
                </div>
                <div className="form-group mb-5">
                    <label className="form-label">Azure group name</label>
                    <input
                        className="form-control h-auto form-control-solid py-4 px-8"
                        style={{ marginBottom: "5px" }}
                        type="text"
                        placeholder="Azure group name"
                        autoComplete={"false"}
                        {...register("azureGroupName", { required: true })}
                    />
                    {formState.errors && formState.errors.azureGroupName && formState.errors.azureGroupName.type === "required" && (<p className="text-danger">Value is required!</p>)}
                </div>
                <div className="form-group mb-5">
                    <label className="form-label">RMS server</label>
                    <input
                        className="form-control h-auto form-control-solid py-4 px-8"
                        style={{ marginBottom: "5px" }}
                        type="text"
                        placeholder="RMS server"
                        autoComplete={"false"}
                        {...register("rmsServer")}
                    />
                    {formState.errors && formState.errors.rmsServer && formState.errors.rmsServer.type === "required" && (<p className="text-danger">Value is required!</p>)}

                </div>
                <div className="form-group mb-5">
                    <label className="form-label">Domains</label>

                    <InputList control={control} register={register} setValue={setValue} getValues={getValues} formState={formState} />

                </div>


            </Modal.Body>

            <Modal.Footer>
                <div className="form-group d-flex float-right">
                    <button type="submit" className={'btn btn-primary'} disabled={isLoading}>
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
                        {isEdit ? "Update" : "Create"}
                    </button>
                    <button type="button" onClick={props.onHide} className={'btn btn-danger ml-2'} disabled={isLoading}>
                        {"Cancel"}
                    </button>

                </div>
            </Modal.Footer>
        </form>
    </Modal>


}

export default EditOrganizationModal;