import * as React from "react";
import { Button, Modal, OverlayTrigger } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ConsumerDomainPostModel, ConsumerDomainResponseModel, IConsumerDomainPostModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import LoadingService from "../../components/loading-indicator/loading-indicator.service";
import { getCosumerDomainsClient } from "../../services/api-clients.service";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import { UtcTimePopover } from "../screenshots/screenshots.component";
import ReactPaginate from "react-paginate";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";


interface IConsumerDomainsModalProps {

    show: boolean;
    onHide: () => void;
}

export interface IPagination {
    currentPage: number,
    totalRecords: number,
    recordsPerPage: number
}


const ConsumerDomainsModal: React.FunctionComponent<IConsumerDomainsModalProps> = (props) => {


    const { register, handleSubmit, formState, setValue } = useForm<IConsumerDomainPostModel>();
    const [consumerDomains, setConsumerDomains] = React.useState<ConsumerDomainResponseModel[]>([]);
    const [isLoading, SetIsLoading] = React.useState<boolean>(false);
    // submit request 
    const [isRequestInProgress, setIsRequestInProgress] = React.useState<boolean>(false);
    const [pagination, setPagination] = React.useState<IPagination>({ currentPage: 1, recordsPerPage: 15, totalRecords: 0 });
    React.useEffect(() => { getConsumerDomains(); }, [pagination.currentPage]);


    const getConsumerDomains = async () => {
        try {
            SetIsLoading(true);
            const client = getCosumerDomainsClient();
            const skip = (pagination.currentPage - 1) * pagination.recordsPerPage;

            const consumerDomainsResp = await client.getConsumerDomains(skip, pagination.recordsPerPage);
            if (consumerDomainsResp) {
                setConsumerDomains(consumerDomainsResp.consumerDomains ?? []);
                setPagination({ ...pagination, totalRecords: consumerDomainsResp.totalRecords });
            } else {
                setConsumerDomains([]);
                setPagination({ currentPage: 1, recordsPerPage: 20, totalRecords: 0 });
            }

        } catch (error) {
            console.log("An error occurred while getting consumer domains.", error);
            ShowErrorFromResponse(error, "An error occurred while getting consumer domains.");
        } finally {
            SetIsLoading(false);
        }
    }

    const onSubmit = async (data: IConsumerDomainPostModel) => {

        console.log("add consumer domain onSubmit", data);
        try {
            setIsRequestInProgress(true);

            const client = getCosumerDomainsClient();
            await client.addConsumerDomain(new ConsumerDomainPostModel({ domain: data.domain }));
            toast.success("Domain successfully added.");
            setValue("domain", "");
            getConsumerDomains();
        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while adding domain.");
        }
        finally {
            setIsRequestInProgress(false);
        }
    }
    const onRemoveDomainClick = async (domain: string) => {

        try {
            setIsRequestInProgress(true);

            const client = getCosumerDomainsClient();
            await client.deleteDomain(domain);
            toast.success("Domain successfully deleted.");
            getConsumerDomains();
        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while deleting domain.");
        }
        finally {
            setIsRequestInProgress(false);
        }
    }


    const totalPages = Math.ceil(pagination.totalRecords / pagination.recordsPerPage);

    return <Modal
        size="lg"
        show={props.show}
        onHide={props.onHide}
        animation={false}
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                Consumer domains
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <form className="form" id="kt_login_signin_form" onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex mb-5" style={{ maxWidth: "500px" }}>
                    <div className="d-flex flex-column " style={{ flex: 1 }}>
                        <label className="form-label">Add domain</label>
                        <input
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Domain name"
                            autoComplete={"false"}
                            {...register("domain", { required: true })}
                        />
                        {formState.errors && formState.errors.domain && (<p className="text-danger">Domain name is required!</p>)}
                    </div>
                    <div className="ml-4 d-flex flex-column">
                        <label className="form-label">&nbsp;</label>
                        <Button variant="primary" size="sm" type="submit" disabled={isRequestInProgress} style={{ minWidth: "100px" }} >Add</Button>
                    </div>

                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th>Domain</th>
                            <th className="align-middle text-center">Created</th>
                            <th></th>

                        </tr>
                    </thead>
                    <tbody>

                        {consumerDomains.map((item) => (<tr key={item.domain} >
                            <td>
                                {item.domain}

                            </td>
                            <td className="align-middle text-center">
                                <DateTimeDisplayUTC date={item.createdAt} />
                            </td>
                            <td className="align-middle text-center"><span style={{ cursor: "pointer" }} onClick={() => onRemoveDomainClick(item.domain)}><i className="fas fa-times text-danger"></i></span></td>

                        </tr>))
                        }

                        {isLoading &&
                            <tr>
                                <td colSpan={3} className="text-center">Loading...</td>
                            </tr>
                        }
                        {
                            !isLoading && pagination.totalRecords == 0 && <tr>
                                <td colSpan={3} className="text-center">No items found.</td>
                            </tr>
                        }


                    </tbody>
                </table>

                {totalPages > 1 &&
                    <div className="d-flex align-items-center">
                        <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me page-item'}
                            breakLinkClassName={'page-link'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            pageCount={totalPages}
                            marginPagesDisplayed={4}
                            pageRangeDisplayed={10}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            forcePage={pagination.currentPage - 1}
                            onPageChange={(page: any) => {
                                console.log("Selected page:", page);
                                setPagination({ ...pagination, currentPage: page.selected + 1 });
                                document.body.scrollTop = 0; // For Safari
                                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                            }}
                            containerClassName={'pagination mb-0'}
                            activeClassName={'active'}
                        />

                    </div>
                }

            </form>

        </Modal.Body>



    </Modal>


}

export default ConsumerDomainsModal;