import React from 'react';
import AsideMenuList from './aside-menu-list.component';

const AsideMenu=()=>{


    return <>
    {/* begin::Menu Container */}
    <div
      id="kt_aside_menu"
      data-menu-vertical="1"
      className={`aside-menu my-4 `}   
      data-menu-scroll="1" 
      data-menu-dropdown-timeout="500"    
    >
      <AsideMenuList/>
    </div>
    {/* end::Menu Container */}
  </>
}


export default AsideMenu

