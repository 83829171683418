import { toast } from "react-toastify";
import React from "react";

export const ShowErrorFromResponse = (response: any, fallbackMessage: string) => {
    console.log("ShowErrorFromResponse", response);
    let errors: string[] = [];

    if (response.errors && response.errors.length > 0) {
        errors = [...errors, ...response.errors];
    }

    if (response.validationErrors) {
        let fields = Object.keys(response.validationErrors);
        for (let f of fields) {
            if (response.validationErrors[f] && response.validationErrors[f].length > 0) {
                errors = [...errors, ...response.validationErrors[f]];
            }
        }
    }

    if (errors && errors.length > 0) {
        toast.error(<span>{errors.map(e => <>{e}<br /></>)}</span>);
    } else {
        toast.error(fallbackMessage);
    }
}