import React from 'react';
import { NavLink } from 'react-router-dom';
import SideBarPlaceholder from "../../../components/sidebar-placeholder/sidebar-placeholder.component";

import "./aside-menu-list.styless.scss";

const AsideMenuList = () => {


  return <>
    {/* begin::Menu Nav */}
    <ul className={`menu-nav`}>
      {/*begin::1 Level*/}
      <li className="menu-item " aria-haspopup="true">
        <NavLink to="/" exact className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon flaticon-home"></i><span className="menu-text">Home</span>
        </NavLink>
      </li>
      <li className="menu-item " aria-haspopup="true">
        <NavLink to="/screenshots" className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon flaticon2-menu"></i><span className="menu-text">Screenshots</span>
        </NavLink>
      </li>
      {/* <li className="menu-item " aria-haspopup="true">
        <NavLink to="/users" className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon flaticon-users-1"></i><span className="menu-text">Users</span>
        </NavLink>
      </li> */}
      <li className="menu-item " aria-haspopup="true">
        <NavLink to="/users" className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon flaticon-users"></i><span className="menu-text">Users</span>
        </NavLink>
      </li>

      <li className="menu-item " aria-haspopup="true">
        <NavLink to="/dwsim-pro-usage" className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon flaticon-users-1"></i><span className="menu-text">DWSIM Pro usage</span>
        </NavLink>
      </li>
      <li className="menu-item " aria-haspopup="true">
        <NavLink to="/dwsim-pro-analytics" className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon flaticon2-analytics"></i><span className="menu-text">DWSIM Pro Analytics</span>
        </NavLink>
      </li>
     

      <li className="menu-item " aria-haspopup="true">
        <NavLink to="/organizations" className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon flaticon2-group"></i><span className="menu-text">Organizations</span>
        </NavLink>
      </li>

      <li className="menu-item " aria-haspopup="true">
        <NavLink to="/feedbacks" className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon flaticon-notes"></i><span className="menu-text">Feedbacks</span>
        </NavLink>
      </li>

      <li className="menu-item " aria-haspopup="true">
        <NavLink to="/host-pools" className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon flaticon2-box"></i><span className="menu-text">Host pools</span>
        </NavLink>
      </li>
      <li className="menu-item " aria-haspopup="true">
        <NavLink to="/disaptcher-usage" className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon flaticon-statistics"></i><span className="menu-text">Dispatcher usage</span>
        </NavLink>
      </li>
      <li className="menu-item " aria-haspopup="true">
        <NavLink to="/elastic-search" className="menu-link " activeClassName="menu-item-active">
          <i className="menu-icon bi bi-search"></i><span className="menu-text">Elastic search</span>
        </NavLink>
      </li>

    </ul>
    {/* end::Menu Nav */}
  </>
}
export default AsideMenuList
