import * as React from 'react';
import Loader from 'react-loader-spinner';
import LoadingService from './loading-indicator.service';


class LoadingIndicator extends React.Component<{}, { isLoading: boolean }>
{
    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        LoadingService.addCallback((isLoading: boolean) => {
            this.setState(s => ({ isLoading: isLoading }));
        });
    }

    render() {
        const { isLoading } = this.state;
      //  console.log("showLoading render called.", isLoading);
        if (isLoading)
            return <div className="text-center" style={{ zIndex: 9999, width: "100%", height: "100%", top:0, bottom:0, left:0, right:0, position: "fixed", background: "rgba(0,0,0,0.3)" }}>
                <div className="spinner-border" role="status" style={{ position: "fixed", top: "50%", left: "50%", height: "50px", width: "50px" }}>
                </div>
            </div>
        else
            return null;
    }
}

export { LoadingService, LoadingIndicator };

