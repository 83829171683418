import React from "react";
import Aside from "./components/aside/Aside.component";
import Header from './components/header/header.component';
import Footer from "./components/footer/footer.component";
import HeaderMobile from './components/header/header-mobile/header-mobile.component';
import HomePage from "../pages/home/home.component";
import LoginPage from "../pages/login/login.component";
import ScreenshotsPage from "../pages/screenshots/screenshots.component";
import HostPoolsPage from "../pages/host-pools/host-pools.component";
import DispatcherUsage from "../pages/dispatcher-usage/disaptcher-usage.component";
import { Route } from "react-router";
import { connect } from 'react-redux';
import { authenticationService } from '../services/authentication-service';
import { setUser } from '../redux/application/application.actions';
import { UserResultModel } from "../swagger-clients/s365-admin-panel-clients.service";
import OrganizationTable from "../pages/organizations/organizations-table.component";
import { LoadingIndicator } from "../components/loading-indicator/loading-indicator.component";
import ElasticSearchPage from "../pages/elastic-search/elastic-search.component";
import { getUserToken } from "../services/api-clients.service";
import { toast } from "react-toastify";
import UserTableWithCaching from "../pages/users/users-table-with-caching.component";
import { DwsimProUsersTable } from "../pages/dwsim-pro-users/dwsim-pro-users-table.component";
import { DwsimProAnalytics } from "../pages/dwsim-pro-analytics/dwsim-pro-analytics.component";
import { FeebacksPage } from "../pages/feedbacks/feedbacks.component";

interface ILayoutProps {
    user?: string;
    setUser: (user: UserResultModel) => void;
}
interface ILayoutState {
    access_token: string;
}


class Layout extends React.Component<ILayoutProps, ILayoutState> {

    constructor(props: ILayoutProps) {
        super(props);
        this.state = { access_token: "" };
    }

    async componentDidMount() {
        await this.getAccessToken();
        if (!this.props.user) {
            try {
                const user = await authenticationService.GetCurrentUser();
                console.log("user result", user);
                if (user) {
                    this.props.setUser(user);
                } else {
                    window.location.href = "/login";
                }
            }
            catch (error) {
                
            }
        }
    }

    async getAccessToken() {
        try {
            const token = await getUserToken();
            this.setState({ access_token: token });

        } catch (error) {
           // console.log("An error occurred while getting access token", error);
          //  toast.error("An error occurred while getting access token");
        }
    }

    render() {
        const { access_token } = this.state;
        return this.props.user ? <div id="body" className="d-flex flex-column flex-root header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable" >
            <HeaderMobile />
            <LoadingIndicator />
            <div className="d-flex flex-column flex-root aside-enabled">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    <Aside />
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <Header />
                        {/*begin::Content*/}
                        <div
                            id="kt_content"
                            className={`content d-flex flex-column flex-column-fluid`}   >
                            {/* <SubHeader /> */}
                            {/*begin::Entry*/}

                            {/* <div className="d-flex flex-column-fluid"> */}
                            {/*begin::Container*/}
                            <Route key="home" exact path="/" component={HomePage} />
                            <Route key="screenshots" exact path="/screenshots" component={() => (<ScreenshotsPage accessToken={access_token} />)} />
                            <Route key="host-pools" exact path="/host-pools" component={HostPoolsPage} />
                            <Route key="organizations" exact path="/organizations" component={OrganizationTable} />
                            <Route key="disaptcher-usage" exact path="/disaptcher-usage" component={DispatcherUsage} />
                            {/* <Route key="users" exact path="/users" component={UsersTable} /> */}
                            <Route key="users" exact path="/users" component={UserTableWithCaching} />
                            <Route key="dwsim-pro-usage" exact path="/dwsim-pro-usage" component={DwsimProUsersTable} />
                            <Route key="dwsim-pro-analytics" exact path="/dwsim-pro-analytics" component={DwsimProAnalytics} />
                            
                            <Route key="login" exact path="/login" component={LoginPage} />
                            <Route key="elastic-search" exact path="/elastic-search" component={ElasticSearchPage} />
                            <Route key="feedbacks" exact path="/feedbacks" component={FeebacksPage} />
                            {/*end::Container*/}
                            {/* </div> */}



                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        {/* <Footer /> */}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>

        </div> : <LoginPage />;
    }
}

const MapStateToProps = (state: any) => ({
    user: state.application.user
})

const DispatchToProps = (dispatch: any) => ({
    setUser: (user: UserResultModel) => dispatch(setUser(user))
})


export default connect(MapStateToProps, DispatchToProps)(Layout);
