import React from "react";

interface IContentWrapperProps {
    children: any;
    className?: string;
}



const ContentWrapper: React.FunctionComponent<IContentWrapperProps> = (props) => {
    return <div className={`d-flex flex-column ${props.className}`} style={{ justifyContent: "center" }}>{props.children}</div>
}

export default ContentWrapper;