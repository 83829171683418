import * as React from "react";
import ReactPaginate from "react-paginate";
import { ErrorResultModel } from "../../../swagger-clients/s365-admin-panel-clients.service";
import { toast } from "react-toastify";
import { getOrganizationsClient } from "../../../services/api-clients.service";
import { OrganizationMemberResponseModel } from "../../../swagger-clients/dashboard-clients.service";

interface IOrganizationMembersTableProps {
    organizationId: number;
}

const OrganizationMembersTable: React.FunctionComponent<IOrganizationMembersTableProps> = (props) => {
    const [members, setMembers] = React.useState<OrganizationMemberResponseModel[]>([]);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [totalRecords, setTotalRecords] = React.useState<number>(0);
    const [recordsPerPage, setRecordsPerPage] = React.useState<number>(10);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const totalPages = Math.ceil(totalRecords / recordsPerPage);


    React.useEffect(() => {
        if (props.organizationId && props.organizationId > 0) {
            getMembers();
        }

    }, [props.organizationId,currentPage]);

    const getMembers = async () => {
        try {
            setIsLoading(true);
            const client = getOrganizationsClient();
            const skip = (currentPage - 1) * recordsPerPage;
            var result = await client.getOrganizationMembers(props.organizationId, null, undefined, skip, recordsPerPage);
            setTotalRecords(result.totalRecords??0);
            setMembers(result.items??[]);


        } catch (error) {
            const errorResponseModel = error as ErrorResultModel;
            if (errorResponseModel && errorResponseModel.errors && errorResponseModel.errors.length > 0) {
                toast.error(errorResponseModel.errors[0]);
            } else {
                toast.error("An error occurred while getting organization members.");
            }
            console.log("An error occurred while getting organization members.", error);
        } finally {
            setIsLoading(false);
        }



    }

    return <> <table className="table">
        <thead>
            <tr>
                <th>Given name</th>
                <th>Surname</th>
                <th>Email</th>

            </tr>
        </thead>
        <tbody>

            {!isLoading && members && members.length > 0 && members.map((member) => (<tr>
                <td>{member.givenName}</td>
                <td>{member.surname}</td>
                <td>{member.privateEmail}</td>
            </tr>))
            }
            {!isLoading && members && members.length == 0 &&
                <tr>
                    <td style={{ textAlign: "center" }} colSpan={3}>No results found</td>
                </tr>
            }{isLoading &&
                <tr>
                    <td style={{ textAlign: "center" }} colSpan={3}>Loading...</td>
                </tr>
            }

        </tbody>
    </table>

        {totalPages > 1 && <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me page-item'}
            breakLinkClassName={'page-link'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            onPageChange={(page) => { console.log("Selected page:", page); setCurrentPage(page.selected + 1); }}
            containerClassName={'pagination'}
            activeClassName={'active'}
        />
        }
    </>
}

export default OrganizationMembersTable;

