import * as React from "react";
import { Modal } from "react-bootstrap";
import OrganizationMembersTable from "../organization-members-table/organization-members-table.component";
import { toast } from "react-toastify";
import { OrganizationResultModel, OrganizationsClient } from "../../../swagger-clients/dashboard-clients.service";
import { getOrganizationsClient } from "../../../services/api-clients.service";
import LoadingService from "../../../components/loading-indicator/loading-indicator.service";
interface IDetailsOrganizationModalProps {
    organizationId: number;
    show: boolean;
    onHide: () => void;
}

interface IDetailsOrganizationModalState {

}

const DetailsOrganizationModal: React.FunctionComponent<IDetailsOrganizationModalProps> = (props) => {

    const [selectedTab, setSelectedTab] = React.useState<number>(1);
    const [organization, setOrganization] = React.useState<OrganizationResultModel>();

    React.useEffect(() => {

        if (props.organizationId) {
            getOrganization(props.organizationId);
        }

    }, [props.organizationId]);


    const getOrganization = async (orgId: number) => {
        try {
            LoadingService.showLoading();
            const client = getOrganizationsClient();
            const organization = await client.getOrganization(orgId);
            setOrganization(organization);


        } catch (error) {
            console.log("An error occurred while getting organization.", error);
            toast.error("An error occurred while getting organization.");
        } finally {
            LoadingService.hideLoading();
        }
    }


    return <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                Organization details
            </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: "5px" }}>


            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className={`nav-link ${selectedTab == 1 ? "active" : ""}`} onClick={() => setSelectedTab(1)} href="#">Details</a>
                </li>

                <li className="nav-item">
                    <a className={`nav-link ${selectedTab == 2 ? "active" : ""}`} onClick={() => setSelectedTab(2)} href="#">Members</a>
                </li>

            </ul>
            {selectedTab == 1 && <div className="form-horizontal p-3">
                <div className="form-group mb-0">
                    <label className="form-label" style={{ fontWeight: 700 }}>Name:</label>
                    <span className="ml-3">{organization ? organization.name : ""}</span>

                </div>
                <div className="form-group  mb-0">
                    <label className="form-label" style={{ fontWeight: 700 }}>Azure group name:</label>
                    <span className="ml-3">{organization ? organization.azureGroupName : ""}</span>
                </div>
                <div className="form-group  mb-0">
                    <label className="form-label" style={{ fontWeight: 700 }}>RMS server:</label>
                    <span className="ml-3">{organization ? organization.rmsServer : ""}</span>
                </div>
                <div className="form-group  mb-0 d-flex flex-row">
                    <label className="form-label" style={{ fontWeight: 700 }}>Domains:</label>
                    <div className="ml-3 d-flex flex-row" style={{ flexFlow: "wrap" }}>{organization && organization.domains ? organization.domains.map((domain, index) => {
                        return <span className="ml-1 badge badge-pill badge-light"> {domain.name}</span>

                    }) : ""}</div>

                </div>
            </div>
            }

            {selectedTab == 2 && <OrganizationMembersTable organizationId={props.organizationId ?? 0} />}

        </Modal.Body>

        <Modal.Footer>
            <div className="form-group d-flex float-right">
                <button type="button" onClick={props.onHide} className={'btn btn-info ml-2'}>
                    {"Close"}
                </button>
            </div>
        </Modal.Footer>

    </Modal>

}

export default DetailsOrganizationModal;