
import { combineReducers } from 'redux';
import ApplicationReducer from './application/application.reducer';



const createRootReducer = (history: any) => combineReducers({
    application: ApplicationReducer
})

export default createRootReducer