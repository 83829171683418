import React from "react";
import Select from "react-select";
import { LoginApplications } from "../../../swagger-clients/s365-admin-panel-clients.service";


type LoginApplicationSelectProps = {
    className?: string;
    placeholder?: string;
    onChange: (selectedValues: (string | number)[] | undefined | null) => void;
}

export const LoginApplicationSelect: React.FC<LoginApplicationSelectProps> = (props) => {


    return <Select
        className={props.className}
        isMulti
        styles={{
            control: (baseStyles, state) => ({
                ...baseStyles,
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0
            })
        }}
        placeholder={props.placeholder}
        options={applicationOptions}
        onChange={(newValues) => {
            const selectedValues = newValues?.filter(x => x !== undefined && x !== null).map(x => x.value) ?? undefined;
            props.onChange(selectedValues as any);
        }} />
}

export type ReactSelectOption = {
    value: string | number | undefined | null;
    label: string;
}

const applicationOptions: ReactSelectOption[] = [
    { value: LoginApplications.Simulate365, label: "Simulate365.com" },
    { value: LoginApplications.DWSIM, label: "DWSIM" },
    { value: LoginApplications.Dashboard, label: "S365 Dashboard" },
    { value: LoginApplications.VM, label: "VM Portal" }

];