import * as React from "react";
import { SimulatorCount } from "../../swagger-clients/dispatcher-next-admin-clients.service";
import cc7Icon from "../../assets/filetype-icons/cc7.png";
import cc8Icon from "../../assets/filetype-icons/ccsim.png";
import dwsimIcon from "../../assets/filetype-icons/dwsim.png";

interface ISimulatorCountProps {
    count: SimulatorCount | undefined;
}

const SimulatorCountComponent: React.FC<ISimulatorCountProps> = (props) => {

    const { count } = props;


    return count !== undefined && (count.cC7! > 0 || count.cC8! > 0 || count.dwsim! > 0) ? <> {
        count.cC7! > 0 &&
        <span className="ml-2 d-inline-flex align-items-center">
            <img src={cc7Icon} className="mr-1" alt={"Chemcad 7"} title={"Chemcad 7"} height="20" width="20" />
            {count.cC7}

        </span>
    }
        {count.cC8! > 0 &&
            <span className="ml-2 d-inline-flex align-items-center">
                <img src={cc8Icon} className="mr-1" alt={"Chemcad 8"} title={"Chemcad 8"} height="20" width="20" />
                {count.cC8}
            </span>
        }
        {count.dwsim! > 0 &&
            <span className="ml-2 d-inline-flex align-items-center">
                <img src={dwsimIcon} className="mr-1" alt={"DWSIM"} title={"DWSIM"} height="20" width="20" />
                {count.dwsim}

            </span>
        }</>
        : <span>-</span>;

}

export default SimulatorCountComponent;