import React from 'react';

export interface ISubHeaderProps {
    title?: string;
    leftItems: any;
    rightItems: any;
}


const SubHeader: React.FunctionComponent<ISubHeaderProps> = (props) => {
    return <div className="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

            <div className="d-flex align-items-center flex-wrap mr-2">
                {props.title &&
                    <>
                        <h5 id="page-title" className="text-dark font-weight-bold mt-2 mb-2 mr-5">{props.title}</h5>
                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
                    </>
                }
                {props.leftItems}
            </div>

            <div className="d-flex align-items-center">
                {props.rightItems}
            </div>

        </div>

    </div>
}
export default SubHeader;