import React from "react";
import { Card, Dropdown, Form, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import SubHeader from "../../layout/components/sub-header/sub-header.component";
import ContentWrapper from "../../layout/components/content-wrapper/content-wrapper.component";
import { DwsimProClient, DwsimProUsagePerUserModel, DwsimProUserModel, GetDwsimProUsagePostModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import LoadingService from "../../components/loading-indicator/loading-indicator.service";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import { EmailWithLinkToDomain } from "../../components/email-with-link-to-domain/email-with-link-to-domain.component";
import { UtcTimePopover } from "../screenshots/screenshots.component";
import { getDwsimProClient } from "../../services/api-clients.service";
import { _copyAndSortWithSecondaryColumn } from "../../utilities/array.helpers";
import UserDetailsModal from "../users/user-details-modal/user-details-modal.component";
import { toast } from "react-toastify";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";
import moment from "moment";
import DatePicker from "react-datepicker";
import { addDays } from "../../utilities/date.utilities";

type DwsimProUsersTableProps = {

}

type UsersUsageDataState = {
    [username: string]: UsersUsageDataModel
}

type UsersUsageDataModel = {
    isLoading: boolean,
    data?: DwsimProUsagePerUserModel
}
type DwsimProUserModelProperties = keyof DwsimProUserModel | string;

const getTodayDate = () => {
    let dateNow = new Date();
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    dateNow.setMilliseconds(0);
    return dateNow;
}

const getDefaultEndDate = () => {
    let tomorrow = addDays(new Date(), 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    return tomorrow;

}

export const DwsimProUsersTable: React.FC<DwsimProUsersTableProps> = (props) => {

    const [users, setUsers] = React.useState<DwsimProUserModel[]>([]);
    const [selectedUser, setSelectedUser] = React.useState<DwsimProUserModel>();
    const [showDetailsModal, setShowDetailsModal] = React.useState<boolean>(false);
    const [hideTestUsers, setHideTestUsers] = React.useState<boolean>(true);
    const [hideConsumers, setHideConsumers] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoadingUsageData, setIsLoadingUsageData] = React.useState<boolean>(false);
    const [usersUsageData, setUsersUsageData] = React.useState<UsersUsageDataState>({});
    const [orderBy, setOrderBy] = React.useState<DwsimProUserModelProperties>("plan");
    const [orderByDescending, setOrderByDescending] = React.useState<boolean>(false);
    const [shouldCallOrderByAfterLoading, setShouldCallOrderByAfterLoading] = React.useState<boolean>(false);
    const [numberOfPaidUsers, setNumberOfPaidUsers] = React.useState<number>();
    const [numberOfTrialUsers, setNUmberOfTrailUsers] = React.useState<number>();
    const [fromDate, setFromDate] = React.useState<Date>();
    const [toDate, setToDate] = React.useState<Date>();

    React.useEffect(() => {
        getDwsimProUsers();
    }, []);



    React.useEffect(() => {
        if (shouldCallOrderByAfterLoading && !isLoading && !isLoadingUsageData) {
            orderColumns(orderBy, orderByDescending);
        }

    }, [orderBy, orderByDescending, isLoading, isLoadingUsageData]);

    const updateUsersUsageState = (username: string, dataModel: UsersUsageDataModel, currentState: UsersUsageDataState) => {
        let usersUsageDataUpdated = { ...currentState };
        usersUsageDataUpdated[`${username}`] = dataModel;
        return usersUsageDataUpdated;
    }

    const deleteUserUsageState = (username: string, currentState: UsersUsageDataState) => {
        let usersUsageDataUpdated = { ...currentState };
        delete usersUsageDataUpdated[`${username}`];
        return usersUsageDataUpdated;
    }

    const getUsersUsageData = async (usersVal: DwsimProUserModel[]) => {
        setUsersUsageData({});
        setIsLoadingUsageData(true);
        if (!usersVal || usersVal.length == 0) return;

        const client = getDwsimProClient();
        for (let i = 0; i < usersVal.length; i++) {
            const user = usersVal[i];
            try {
                setUsersUsageData(s => updateUsersUsageState(user.usernameWithoutDomain, { isLoading: true, data: undefined }, s));
                const usageData = await client.getDwsimProUsage(new GetDwsimProUsagePostModel({ usernameWithoutDomain: user.usernameWithoutDomain }));
                setUsersUsageData(s => updateUsersUsageState(user.usernameWithoutDomain, { isLoading: false, data: usageData }, s));


            } catch (error) {
                console.log(`An error occurred while loading usage data for user '${user.usernameWithoutDomain}'.`, error);
                setUsersUsageData(s => deleteUserUsageState(user.usernameWithoutDomain, s));
            }
        }
        setIsLoadingUsageData(false);
    }

    const getDwsimProUsers = async () => {
        try {
            //LoadingService.showLoading();
            setIsLoading(true);

            const client = getDwsimProClient();
            const fromDateUtc = fromDate ? new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate())) : undefined;
            const toDateUtc = toDate ? new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate())) : undefined;

            const usersResp = await client.getDwsimProUsers(hideTestUsers, hideConsumers, fromDateUtc, toDateUtc);
            const sortedUsers = _copyAndSortWithSecondaryColumn<DwsimProUserModel>(usersResp, orderBy, "usernameWithoutDomain", orderByDescending);

            setUsers(sortedUsers);

            const numerOfPaid = sortedUsers?.filter(x => x.plan == "Paid")?.length;
            const numberOfTrial = sortedUsers?.filter(x => x.plan == "Trial")?.length;
            setNumberOfPaidUsers(numerOfPaid);
            setNUmberOfTrailUsers(numberOfTrial);
            setIsLoading(false);
            await getUsersUsageData(sortedUsers);


        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while getting DWSIM Pro users.");
        } finally {
            //  LoadingService.hideLoading();
            setIsLoading(false);
        }
    }

    const orderColumns = (orderByVal: DwsimProUserModelProperties, isDescending: boolean) => {


        const columnSplitValue = orderByVal.split('.');
        if (columnSplitValue.length == 1 && orderByVal !== "lastVisit") {
            const sortedUsers = _copyAndSortWithSecondaryColumn<DwsimProUserModel>(users, orderBy, "usernameWithoutDomain", isDescending);
            setUsers(sortedUsers);
        } else {
            // sort by Usage data
            const usageDataArray = Object.keys(usersUsageData).map(username => {
                const userUsageData = usersUsageData[username];
                return { ...userUsageData.data, username: username };
            });
            const sortedUsageDataArray = _copyAndSortWithSecondaryColumn<any>(usageDataArray, orderByVal, "usernameWithoutDomain", orderByDescending);
            const sortedUsers: DwsimProUserModel[] = sortedUsageDataArray.map((usageData) => {
                const user = users.find(x => x.usernameWithoutDomain == usageData.username);
                return user;
            }).filter(x => x !== undefined) as DwsimProUserModel[];
            setUsers(sortedUsers);
        }


    }
    const getSortValue = (columnName: DwsimProUserModelProperties) => {

        if (orderBy == columnName) {
            if (orderByDescending) {
                return "desc";
            } else {
                return "asc";
            }
        }

        return "";
    }
    const onColumnClick = (column: DwsimProUserModelProperties) => {
        if (orderBy == column) {
            setOrderByDescending(!orderByDescending);
        } else {
            setOrderBy(column);
            setOrderByDescending(true);
        }
        setShouldCallOrderByAfterLoading(true);
    }
    const onUserRowClick = (user: DwsimProUserModel) => {
        if (user && user.userId) {
            setSelectedUser(user); setShowDetailsModal(true);
        }
        else {
            toast.error("User Id not available. Update cached users list.");
        }
    }


    const onTodayClick = () => {
        setFromDate(getTodayDate());
        setToDate(getDefaultEndDate());

    }
    const onLast7DaysClick = () => {
        const startDate = addDays(getTodayDate(), -7);
        setFromDate(startDate);
        setToDate(getDefaultEndDate());

    }
    const onLast30DaysClick = () => {
        const startDate = addDays(getTodayDate(), -30);
        setFromDate(startDate);
        setToDate(getDefaultEndDate());

    }

    const onLastMonthClick = () => {
        const dateNow = new Date();
        const firstInMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        let firstInNextMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        firstInNextMonth.setMonth(firstInMonth.getMonth() + 1);
        const lastDayOfMonth = addDays(firstInNextMonth, -1);
        setFromDate(firstInMonth);
        setToDate(lastDayOfMonth);

    }

    const onLastYearClick = () => {
        const dateNow = new Date();
        const januaryFirst = new Date(dateNow.getFullYear(), 0, 1, 0, 0, 0);
        setFromDate(januaryFirst);
        setToDate(getDefaultEndDate());

    }

    const onAddDayClick = (numberOfDays: number) => {
        const newDate = addDays(fromDate ?? new Date(), numberOfDays);
        setFromDate(newDate);
    }
    const onAddMonthClick = (numberOfMonths: number) => {
        let newDate = !!fromDate ? new Date(fromDate) : new Date();
        newDate.setMonth(newDate.getMonth() + numberOfMonths);
        setFromDate(newDate);
    }

    const rightItems = [
        <div className="pr-4" style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRight: "1px solid lightgrey" }}>
            <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{numberOfPaidUsers ?? "-"}</b>
            <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>Paid users</span>
        </div>,
        <div className="ml-4" style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{numberOfTrialUsers ?? "-"}</b>
            <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>Trial users</span>
        </div>
    ];

    const leftItems = [

        <div className="col-auto mr-2">
            <Form.Check type="checkbox"
                checked={hideTestUsers}
                onChange={(ev: any) => { setHideTestUsers(ev.target.checked); }}
                label="Hide test users" />
        </div>,

        <div className="col-auto mr-2">
            <Form.Check type="checkbox"
                checked={hideConsumers}
                onChange={(ev: any) => { setHideConsumers(ev.target.checked); }}
                label="Hide consumers" />
        </div>,
        <div className="input-group mr-2" style={{ width: '380px' }}>
            <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
            <div className="input-group__datepicker-wrapper">
                <DatePicker
                    id="fromDate"
                    className="form-control"
                    selected={fromDate}
                    onChange={date => {
                        if (!date) { setFromDate(undefined); }
                        else { setFromDate(new Date(Date.parse(date != null ? date.toString() : ""))) }
                    }}
                    showTimeSelect
                    maxDate={toDate}
                    placeholderText="Date from"
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm" />
            </div>
            <span className="input-group-text">-</span>

            <div className="input-group__datepicker-wrapper">
                <DatePicker
                    id="toDate"
                    className="form-control"
                    selected={toDate}
                    onChange={date => {
                        if (!date) { setToDate(undefined); }
                        else { setToDate(new Date(Date.parse(date != null ? date.toString() : ""))) }
                    }}
                    showTimeSelect
                    maxDate={new Date()}
                    placeholderText="Date to"
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm" />
            </div>
            <Dropdown>
                <Dropdown.Toggle variant="primary" className="datePickerWithDropdown" id="dropdown-basic" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={onTodayClick} >Today</Dropdown.Item>
                    <Dropdown.Item onClick={onLast7DaysClick}>Last 7 days</Dropdown.Item>
                    <Dropdown.Item onClick={onLast30DaysClick}>Last 30 days</Dropdown.Item>
                    <Dropdown.Item onClick={onLastMonthClick}>This month</Dropdown.Item>
                    <Dropdown.Item onClick={onLastYearClick}>This year</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddDayClick(1) }}>+ day</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddDayClick(-1) }}>- day</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddMonthClick(1) }}>+ month</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddMonthClick(-1) }}>- month</Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>


        </div>,
        <div className="col-auto">
            <button type="button" className="btn btn-primary" onClick={() => { getDwsimProUsers(); }} >Search</button>
        </div>,
        (isLoadingUsageData && <div className="col-auto mr-2">
            <Spinner animation="border" size="sm" /> Loading data, please wait...
        </div>)

    ];


    return <><SubHeader key="sub-header" title="DWSIM Pro usage" leftItems={leftItems} rightItems={rightItems} />
        <ContentWrapper key="content-wrapper" className="pl-4 pr-4">
            <Card>
                <Card.Body>

                    <div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className={"align-middle sortable " + getSortValue("usernameWithoutDomain")}
                                        onClick={() => { onColumnClick("usernameWithoutDomain") }}>Username</th>
                                    <th className={"align-middle sortable " + getSortValue("userEmail")}
                                        onClick={() => { onColumnClick("userEmail") }}>Email</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("plan")}
                                        onClick={() => { onColumnClick("plan") }}>Plan</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("portalSource")}
                                        onClick={() => { onColumnClick("portalSource") }}>Source</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("hasSubmittedOrganizationMail")}
                                        onClick={() => { onColumnClick("hasSubmittedOrganizationMail") }}>Org. email</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("createdAt")}
                                        onClick={() => { onColumnClick("createdAt") }}>Started at</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("expiresAt")}
                                        onClick={() => { onColumnClick("expiresAt") }}>Expires at</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last7Days.daysUsed")}
                                        onClick={() => { onColumnClick("last7Days.daysUsed") }}>
                                        <span>Days used</span><br />
                                        <span>(-7)</span>
                                    </th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last7Days.screenshotsFound")}
                                        onClick={() => { onColumnClick("last7Days.screenshotsFound") }}>
                                        <span>Screenshots</span><br />
                                        <span>(-7)</span>
                                    </th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last30Days.daysUsed")}
                                        onClick={() => { onColumnClick("last30Days.daysUsed") }}>
                                        <span>Days used</span><br />
                                        <span>(-30)</span>
                                    </th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last30Days.screenshotsFound")}
                                        onClick={() => { onColumnClick("last30Days.screenshotsFound") }}>
                                        <span>Screenshots</span><br />
                                        <span>(-30)</span>
                                    </th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last365Days.daysUsed")}
                                        onClick={() => { onColumnClick("last365Days.daysUsed") }}>
                                        <span>Days used</span><br />
                                        <span>(-365)</span>
                                    </th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last365Days.screenshotsFound")}
                                        onClick={() => { onColumnClick("last365Days.screenshotsFound") }}>
                                        <span>Screenshots</span><br />
                                        <span>(-365)</span>
                                    </th>

                                    <th className={"align-middle text-center sortable " + getSortValue("lastVisit")}
                                        onClick={() => { onColumnClick("lastVisit") }}>Last visit</th>
                                </tr>
                            </thead>
                            <tbody>

                                {users && users.length > 0 && users.map((user, i) => (
                                    <tr key={`${user.usernameWithoutDomain}-${i}`}
                                        className={user.plan == "Paid" ? "table-info" : undefined}
                                        onClick={() => { onUserRowClick(user) }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <td className="align-middle">
                                            {user.usernameWithoutDomain} <MaxRowsLoadedWarning username={user.usernameWithoutDomain} usersUsageData={usersUsageData} />
                                        </td>
                                        <td className="align-middle"><EmailWithLinkToDomain email={user.userEmail} /> </td>
                                        <td className="align-middle  text-center">{user.plan} </td>
                                        <td className="align-middle  text-center">{user.portalSource}</td>
                                        <td className="align-middle  text-center">{user.hasSubmittedOrganizationMail ? "YES" : "NO"}</td>
                                        <td className="align-middle  text-center">
                                            {!!user.createdAt ?
                                                <span>{moment(user.createdAt).format("DD.MM.YYYY")}</span>
                                                : "-"}
                                        </td>
                                        <td className="align-middle  text-center">
                                            {!!user.expiresAt ? <span>{moment(user.expiresAt).format("DD.MM.YYYY")}</span>
                                                : "-"}
                                        </td>
                                        <td className="align-middle text-center">
                                            <UserUsageDataValue property="last7Days.daysUsed"
                                                username={user.usernameWithoutDomain}
                                                usersUsageData={usersUsageData} />
                                        </td>
                                        <td className="align-middle text-center">
                                            <UserUsageDataValue property="last7Days.screenshotsFound"
                                                username={user.usernameWithoutDomain}
                                                usersUsageData={usersUsageData} />
                                        </td>
                                        <td className="align-middle text-center">
                                            <UserUsageDataValue property="last30Days.daysUsed"
                                                username={user.usernameWithoutDomain}
                                                usersUsageData={usersUsageData} />
                                        </td>
                                        <td className="align-middle text-center">
                                            <UserUsageDataValue property="last30Days.screenshotsFound"
                                                username={user.usernameWithoutDomain}
                                                usersUsageData={usersUsageData} />
                                        </td>
                                        <td className="align-middle text-center">
                                            <UserUsageDataValue property="last365Days.daysUsed"
                                                username={user.usernameWithoutDomain}
                                                usersUsageData={usersUsageData} />
                                        </td>
                                        <td className="align-middle text-center">
                                            <UserUsageDataValue property="last365Days.screenshotsFound"
                                                username={user.usernameWithoutDomain}
                                                usersUsageData={usersUsageData} />
                                        </td>
                                        <td className="align-middle text-center">
                                            <UserUsageDataValue property="lastVisit"
                                                username={user.usernameWithoutDomain}
                                                isDateTime
                                                usersUsageData={usersUsageData} />
                                        </td>


                                    </tr>))
                                }

                                {isLoading &&
                                    <tr>
                                        <td colSpan={10} className="text-center">Loading...</td>
                                    </tr>
                                }
                                {!isLoading && users.length == 0 &&
                                    <tr>
                                        <td colSpan={10} className="text-center">No users found.</td>
                                    </tr>
                                }


                            </tbody>
                        </table>

                        {showDetailsModal && selectedUser && <UserDetailsModal
                            show={true}
                            userId={selectedUser?.userId ?? ""}
                            userDisplayName={selectedUser?.userLogin}
                            onReloadUsers={() => { getDwsimProUsers(); }}
                            userPrincipalName={!!(selectedUser?.usernameWithoutDomain) ? `${selectedUser.usernameWithoutDomain}@simulate365.com` : ""}
                            onHide={() => { setSelectedUser(undefined); setShowDetailsModal(false); }}
                            onMotherTicketChanged={() => { }} />
                        }
                    </div>


                </Card.Body>
            </Card>
        </ContentWrapper></>
}

type UserUsageDataValueProps = {
    property: string;
    username: string;
    isDateTime?: boolean;
    usersUsageData: { [username: string]: { isLoading: boolean, data?: DwsimProUsagePerUserModel } }
}



const UserUsageDataValue: React.FC<UserUsageDataValueProps> = (props): JSX.Element => {

    const usageData = props.usersUsageData[props.username];
    const propertyNameSplit = props.property.split(".");


    if (!usageData) return <span key={`${props.username}-${props.property}`}>-</span>;

    if (usageData.isLoading) return <span key={`${props.username}-${props.property}`}>Loading...</span>;

    if (!usageData.data) return <span key={`${props.username}-${props.property}`}>-</span>;
    //@ts-ignore
    const propertyValue = propertyNameSplit.length > 1 ? usageData.data[propertyNameSplit[0]]?.[propertyNameSplit[1]] : usageData.data[propertyNameSplit[0]];

    if (props.isDateTime) {
        return <span key={`${props.username}-${props.property}`}>{propertyValue ? <DateTimeDisplayUTC date={propertyValue} hideTime /> : "-"}</span>;
    }

    return <span key={`${props.username}-${props.property}`}>{propertyValue ?? "-"}</span>;
}

type MaxRowsLoadedWarningProps = {
    username: string;
    usersUsageData: { [username: string]: { isLoading: boolean, data?: DwsimProUsagePerUserModel } }
}

const MaxRowsLoadedWarning: React.FC<MaxRowsLoadedWarningProps> = (props) => {
    if (!props.usersUsageData || !props.usersUsageData[props.username]
        || !props.usersUsageData[props.username].data || !props.usersUsageData[props.username].data?.maxRowsLoaded) {
        return null;
    }

    return <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={MaxRowsLoadedPopover}>
        <span className="ms-2"><i className="bi bi-exclamation-triangle"></i></span>
    </OverlayTrigger>
}

export const MaxRowsLoadedPopover = (
    <Popover id="popover-positioned-top" >
        <span style={{ padding: "5px" }}> Maximum number of rows was loaded which will cause some data inaccuracy.</span>
    </Popover>
);