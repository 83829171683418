import React from "react";
import { Alert, Modal, ProgressBar } from "react-bootstrap";
import { getTelemetryScreenShotsClient } from "../../services/api-clients.service";
import { GenerateZipStatus } from "../../swagger-clients/telemetry-clients.service";

type DownloadScreenshotsProgressDialogProps = {
    downloadRequestId: string;
    show: boolean;
    accessToken: string;
    onHide: () => void;
}

export const DownloadScreenshotsProgressDialog: React.FC<DownloadScreenshotsProgressDialogProps> = (props) => {

    const intervalIdRef = React.useRef<NodeJS.Timeout>();    
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [showProgressBar, setShowProgressBar] = React.useState<boolean>(true);
    const [processFilesCount, setProccessFilesCount] = React.useState<number>(0);
    const [totalFilesCount, setTotalFilesCount] = React.useState<number>(1);



    React.useEffect(() => {
        intervalIdRef.current = setInterval(() => {
            checkGenerationStatus();
        }, 1000);
    }, []);

    const stopStatusCheck = () => {
        if (intervalIdRef.current)
            clearInterval(intervalIdRef.current);
    }

    const onCloseClick = () => {
        props.onHide();
    }

    const checkGenerationStatus = async () => {

        if (props.downloadRequestId) {
            try {
                setErrorMessage(undefined);
                const client = getTelemetryScreenShotsClient();
                const status = await client.getGenerateZipRequestStatus(props.downloadRequestId);
                if (status) {
                    if (status.status == GenerateZipStatus.Finished || status.status == GenerateZipStatus.Failed) {
                        stopStatusCheck();
                    }
                    if (status.status == GenerateZipStatus.Finished) {
                        downloadFile();
                    }
                    if (status.status == GenerateZipStatus.Failed) {
                        setErrorMessage(status.errorMessage);
                    }                    
                    if (status.status == GenerateZipStatus.InProgress) {
                        setShowProgressBar(true);
                        setProccessFilesCount(status.finishedCount ?? 0);
                        setTotalFilesCount(status.totalCount ?? 0);
                    }
                }
            } catch (error) {
                console.log("An error occurred while getting status of request to generate screenshots zip.", error);
                setErrorMessage("An error occurred while getting status of request to generate screenshots zip.");
            }
        }
    }

    const downloadFile = async () => {        
        window.location.href = `${process.env.REACT_APP_TELEMETRY_SERVICE_URL}/api/screenshots/download/${props.downloadRequestId}?access_token=${props.accessToken}`;
        onCloseClick();
    }


    return <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
        size="lg"
        centered={true}
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                Download screenshots status
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>           
            {!!errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {!errorMessage && showProgressBar &&
                <ProgressBar
                    variant="success"
                    min={0}
                    max={totalFilesCount}
                    now={processFilesCount}
                    animated
                    striped />}
        </Modal.Body>

    </Modal>
}