import React from 'react';
import { Switch } from 'react-router';
import { Router, Route } from 'react-router-dom';
import Layout from './layout/layout.component';
import { Provider } from "react-redux";
import store, { history } from './redux/store';
import { ToastContainer } from 'react-toastify';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

class App extends React.Component<{}, {}> {

  constructor(props: any) {
    super(props);
  }

   render() {
    return (
      <div className="App">
        <Provider store={store}>
        <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />

        <Router history={history}>
          <Switch>
            <Route path="/" component={Layout} />
          </Switch>
        </Router>
        </Provider>
       
      </div>
    );
  }

}

export default App;
