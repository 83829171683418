import { UserResultModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import { ApplicationTypes } from "./application.types"

interface IApplicationReducerState {
    user?: UserResultModel;
}

const INITIAL_STATE: IApplicationReducerState = {
    user: undefined
}

const ApplicationReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case ApplicationTypes.SET_USER:
            return {
                ...state,
                user: action.user
            }
        default:
            return state;
    }


}

export default ApplicationReducer;