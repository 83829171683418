import * as React from 'react';
import ContentWrapper from '../../layout/components/content-wrapper/content-wrapper.component';
import SubHeader from '../../layout/components/sub-header/sub-header.component';
import { LogMessageModel, ScreenshotsClient, ScreenshotsQuery } from '../../swagger-clients/s365-admin-panel-clients.service';
import { Alert, Button, ButtonGroup, Card, Dropdown, OverlayTrigger, Popover, PopoverContent, Spinner, ToggleButton } from 'react-bootstrap';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import ImageModal from "../../components/image-modal/image-modal.component";
import SidebarPlaceHolderService from "../../components/sidebar-placeholder/sidebar-placeholder-service";
import ScreenshotsSearch from "./screenshots-search.component";
import { saveAs } from 'file-saver';
import "./screenshots.styless.scss";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { LoadingService } from "../../components/loading-indicator/loading-indicator.component";
import { getTelemetryScreenShotsClient, getUserToken } from '../../services/api-clients.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DownloadScreenshotsRequestModel } from '../../swagger-clients/telemetry-clients.service';
import { DownloadScreenshotsProgressDialog } from './download-screenshots-progress-dialog.component';
import { ShowErrorFromResponse } from '../../utilities/response-processor';

interface IScreenShotsPageProps {
    accessToken: string;
}
export interface IPagination {
    currentPage: number;
    totalRecords: number;
    recordsPerPage: number;
}

interface IScreenShotsPageState {
    logMessages: LogMessageModel[];
    pagination: IPagination;
    selectedUser?: string;
    selectedComputerName?: string;
    selectedLog?: LogMessageModel;
    selectedLogIndex?: number;
    showImageModal: boolean;
    selectedMessages: LogMessageModel[];
    isLoading: boolean;
    view: ViewState;
    activeUsers: string[];
    hasMaxResponsesReached: boolean;
    downloadRequestId?: string;
    showDownloadScreenshotsProgressDialog: boolean;

}
enum ViewState {
    Fullscreen,
    Cards
}

class ScreenshotsPage extends React.Component<IScreenShotsPageProps, IScreenShotsPageState>{


    private shiftPressed: boolean = false;

    constructor(props: IScreenShotsPageProps) {
        super(props);
        this.registerShiftKeyEvents();

        this.state = {
            logMessages: [],
            pagination: {
                currentPage: 1,
                totalRecords: 0,
                recordsPerPage: 50
            },
            showImageModal: false,
            selectedMessages: [],
            view: ViewState.Cards,
            activeUsers: [],
            isLoading: false,
            hasMaxResponsesReached: false,
            showDownloadScreenshotsProgressDialog: false
        };
    }

    async componentDidMount() {
        this.updateBodyTagId("screenshoots-page");
        LoadingService.addCallback((isLoading: boolean) => {
            this.setState(s => ({ isLoading: isLoading }));
        });
    }

    componentWillUnmount() {
        this.updateBodyTagId("");
        SidebarPlaceHolderService.setContent(<></>);
    }

    updateSidebarPlaceholder(logMessages: LogMessageModel[]) {
        const { activeUsers, hasMaxResponsesReached } = this.state;
        SidebarPlaceHolderService.setContent(
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div style={{ padding: '9px 25px', fontWeight: 'bold', backgroundColor: 'rgb(243, 246, 249)' }}><i className="fas fa-user menu-icon" style={{ color: "#3699FF", marginRight: '12px' }} /> Filter by Username</div>
                <div style={{ flex: '1 1 auto', overflowY: "auto", height: '0px' }}>
                    <ul className='p-0 m-3' style={{ listStyle: "none" }}>
                        {hasMaxResponsesReached && <li className="alert alert-warning p-1 text-center" style={{ margin: "10px", fontSize: '0.8em' }}>
                            Maximum of 10000 results reached which will affect accuracy of data.
                        </li>}
                        {
                            activeUsers.map(user => {
                                return <li><button className="btn btn-link p-0"
                                    onClick={this.onUsernameClick.bind(this, user)}>
                                    {user} </button></li>
                            })
                        }
                    </ul>
                </div>
            </div>);
    }



    registerShiftKeyEvents() {
        document.addEventListener('keydown', (event) => {
            if (event.key === "Shift") {
                if (!this.shiftPressed) {
                    this.shiftPressed = true;
                }

            }
        });

        document.addEventListener('keyup', (event) => {
            if (event.key === "Shift") {
                this.shiftPressed = false;
            }
        });
    }

    getLogMessages = async (user: string, computerName: string, fromDate: Date, toDate: Date, currentPage: number, recordsPerPage: number) => {

        const { selectedLogIndex } = this.state;

        try {
            LoadingService.showLoading();
            const client = new ScreenshotsClient(process.env.REACT_APP_BASE_URL);
            const startDateUtc = new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDate.getHours(), fromDate.getMinutes(), fromDate.getSeconds()));
            const endDateUtc = new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDate.getHours(), toDate.getMinutes(), toDate.getSeconds()));

            const query = {
                username: user,
                computerName: computerName,
                fromDate: startDateUtc,
                toDate: endDateUtc,
                take: recordsPerPage,
                skip: (currentPage - 1) * recordsPerPage
            } as unknown as ScreenshotsQuery;
            const response = await client.getScreenShots(query);



            this.setState(s => ({
                ...s,
                selectedUser: undefined,
                selectedComputerName: undefined,
                logMessages: response.messages,
                selectedLog: selectedLogIndex !== undefined ? response.messages[selectedLogIndex] : undefined,
                pagination: { ...s.pagination, totalRecords: response.totalResults, currentPage: currentPage, recordsPerPage: recordsPerPage } as IPagination,
                activeUsers: response.activeUsers ?? [],
                hasMaxResponsesReached: response.maxResponsesReached
            }));

            this.updateSidebarPlaceholder(response.messages);



        } catch (error) {
            ShowErrorFromResponse(error, "An error ocurred while loading GrayLog messages with screenshots data.");
        } finally {
            LoadingService.hideLoading();
        }


    }


    SetCurrentPage = (page: number) => {
        this.setState(s => ({ pagination: { ...s.pagination, currentPage: page } as IPagination }));
    }
    onLogMessageClicked(logMessage: LogMessageModel, logMessageIndex: number) {
        if (this.shiftPressed) {
            this.toggleSelectedMessage(logMessage);
        } else {
            this.setState({ selectedLog: logMessage, selectedLogIndex: logMessageIndex, showImageModal: true });
        }
    }

    toggleSelectedMessage(logMessage: LogMessageModel) {
        const { selectedMessages } = this.state;
        const existingMessage = selectedMessages.find(value => value.screenshotFileName == logMessage.screenshotFileName);
        if (existingMessage) {
            const newMessages = selectedMessages.filter(value => value.screenshotFileName !== logMessage.screenshotFileName);
            this.setState(s => ({ selectedMessages: newMessages }));
        } else {
            this.setState(s => ({ selectedMessages: [...s.selectedMessages, logMessage] }));
        }
        console.log("Selected messages", selectedMessages);
    }

    isMessageSelected(message: LogMessageModel): boolean {

        const { selectedMessages } = this.state;
        const selectedMessage = selectedMessages.find((value) => value.screenshotFileName == message.screenshotFileName);

        return !(selectedMessage === undefined);

    }

    updateBodyTagId(value: string) {
        var body = document.getElementsByTagName("body")[0];
        body.id = value;
    }


    async requestToDownloadScreenshots() {

        try {
            const { selectedMessages } = this.state;
            if (selectedMessages.length > 0) {
                LoadingService.showLoading();
                const client = getTelemetryScreenShotsClient();
                const model = new DownloadScreenshotsRequestModel({
                    filenames: selectedMessages.map(m => m.screenshotFileName)
                });
                const resp = await client.submitGenerateZipRequest(model);
                this.setState(s => ({ ...s, downloadRequestId: resp?.requestId, showDownloadScreenshotsProgressDialog: true }));


            } else {
                toast.error("No selected images.");
            }
        }
        catch (error) {
            toast.error(`An error ocurred while trying to submit request to download screenshots.`);
        }
        finally {
            LoadingService.hideLoading();
        }
    }
    onItemPerPageChanged(recordsPerPageNew: number) {
        this.setState(s => ({ pagination: { ...s.pagination, recordsPerPage: recordsPerPageNew } }));
    }

    onUsernameClick(username: string) {
        this.setState(s => ({
            selectedUser: username,
            pagination: {
                ...s.pagination, currentPage: 1
            }
        }));
    }   

    onComputernameClick(computerName: string) {
        this.setState(s => ({
            selectedComputerName: computerName,
            pagination: {
                ...s.pagination, currentPage: 1
            }
        }))
    }
    onPreviousImageClick = async () => {
        const { logMessages, selectedLogIndex, pagination } = this.state;

        if (selectedLogIndex == undefined) return;

        if (selectedLogIndex - 1 >= 0) {
            this.setState(s => ({ ...s, selectedLog: logMessages[selectedLogIndex - 1], selectedLogIndex: selectedLogIndex - 1 }));
        }
        else if (selectedLogIndex == 0) {
            if (pagination.currentPage > 1) {
                this.SetCurrentPage(pagination.currentPage - 1);
                this.setState(s => ({ ...s, selectedLogIndex: pagination.recordsPerPage - 1 }));
            }
        }
    }

    onNextImageClick = async () => {
        const { logMessages, selectedLogIndex, pagination } = this.state;

        if (selectedLogIndex == undefined) return;

        if (selectedLogIndex + 1 <= logMessages.length - 1) {
            this.setState(s => ({ ...s, selectedLog: logMessages[selectedLogIndex + 1], selectedLogIndex: selectedLogIndex + 1 }));
        }
        else if (selectedLogIndex + 1 == logMessages.length) {
            const totalPages = Math.ceil(pagination.totalRecords / pagination.recordsPerPage);
            if (totalPages > pagination.currentPage) {
                this.SetCurrentPage(pagination.currentPage + 1);
                this.setState(s => ({ ...s, selectedLogIndex: 0 }));
            }
        }
    }

    render() {

        const { logMessages, selectedLog, selectedLogIndex,isLoading,
            showImageModal, selectedMessages, selectedUser, selectedComputerName, downloadRequestId, showDownloadScreenshotsProgressDialog } = this.state;
        const { totalRecords, currentPage, recordsPerPage } = this.state.pagination;
        const totalPages = Math.ceil(totalRecords / recordsPerPage);
        const rightItems = [<Dropdown as={ButtonGroup}>
            <Button disabled={!(selectedMessages.length > 0)} onClick={this.requestToDownloadScreenshots.bind(this)} variant="primary">Download scr.</Button>

            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => { this.setState(s => ({ selectedMessages: [...s.logMessages] })) }}>Select all</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.setState(s => ({ selectedMessages: [] })) }}>Deselect all</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>];



        const leftItems = [
            <ButtonGroup className='toogle-buttons-custom'>
                <ToggleButton
                    key="cards"
                    id={`radio-cards`}
                    type="radio"
                    variant={'outline-primary'}
                    size='sm'
                    name="radio"
                    value={this.state.view}
                    checked={this.state.view === ViewState.Cards}
                    onChange={(e) => this.setState(s => ({ ...s, view: ViewState.Cards }))}
                >
                    <i className="bi bi-grid-3x3-gap"></i>
                </ToggleButton>
                <ToggleButton
                    key="fullscreen"
                    id={`radio-fullscreen`}
                    type="radio"
                    variant={'outline-primary'}
                    size='sm'
                    name="radio"
                    value={this.state.view}
                    checked={this.state.view === ViewState.Fullscreen}
                    onChange={(e) => this.setState(s => ({ ...s, view: ViewState.Fullscreen }))}
                >
                    <i className="bi bi-view-stacked"></i>
                </ToggleButton>
            </ButtonGroup>
        ];





        return <div data-page-title="screenshots-page">
            <SubHeader key="sub-header" title="Log screenshots" leftItems={leftItems} rightItems={rightItems} />
            <ContentWrapper key="content-wrapper">
                <Card className='mb-4' style={{ marginRight: "auto", marginLeft: "auto", width: "100%" }}>
                    <ScreenshotsSearch key="ScreenshotsSearch"
                        page={currentPage}
                        user={selectedUser}
                        computerName={selectedComputerName}
                        recordsPerPage={recordsPerPage}
                        selectedMessages={selectedMessages}
                        getLogMessages={this.getLogMessages.bind(this)}
                        totalRecords={totalRecords}
                        onRecordsPerPageChanged={this.onItemPerPageChanged.bind(this)} />
                </Card>
                {logMessages.length > 0 && <Card style={{ marginRight: "auto", marginLeft: "auto", width: "100%" }}>
                    <Card.Body>

                        <div className="row">
                            {
                                logMessages.map((message, messageIndex) => {
                                    return <div key={message.id}
                                        className={this.state.view == ViewState.Cards ? "col-sm-12 col-md-4 col-lg-3 col-xl-2" : "col-sm-12"}
                                    >

                                        <div className="card" style={{ margin: "5px", userSelect: "none" }}>
                                            <span className="far fa-check-circle item-selected"
                                                onClick={() => this.toggleSelectedMessage(message)}
                                                style={{ color: this.isMessageSelected(message) ? "green" : "lightgray", cursor: "pointer" }}></span>
                                            <div className="card-body" style={{ padding: "5px" }}>
                                                <h5 className="card-title mb-0">
                                                    <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={UtcTimePopover}>
                                                        <span>{moment(message.timestamp).utc().format('DD-MM-YYYY HH:mm:ss')}</span>
                                                    </OverlayTrigger> &nbsp;
                                                    <a href={message.logUrl} target="_blank">
                                                        <i className="fas fa-external-link-alt" style={{ fontSize: "13px", marginLeft: "5px" }} />
                                                    </a>
                                                </h5>
                                                <div>by <button className="btn btn-link p-0 m-0"
                                                    onClick={this.onUsernameClick.bind(this, message.user_name)}>
                                                    {message.user_name} </button></div>
                                                <div className="mb-2">on <button className="btn btn-link p-0 m-0"
                                                    onClick={this.onComputernameClick.bind(this, message.computerName)}>
                                                    {message.computerName}</button>
                                                </div>
                                                <div style={{ position: "relative", minHeight: "100px" }}>


                                                    <LazyLoadImage
                                                        alt="screenshot"
                                                        placeholder={<Spinner animation={'border'} style={{ position: "absolute", top: "calc(50% - 18px)", left: "calc(50% - 18px)" }}
                                                        />}
                                                        onClick={() => { this.onLogMessageClicked.call(this, message, messageIndex); }}
                                                        style={{ maxWidth: "100%", maxHeight: "100%", cursor: "pointer" }}
                                                        src={`${process.env.REACT_APP_TELEMETRY_SERVICE_URL}/api/screenshots/${message.screenshotFileName}?access_token=${this.props.accessToken}`} />

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                })
                            }

                        </div>


                        {totalPages > 1 &&
                            <div className="d-flex align-items-center">
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me page-item'}
                                    breakLinkClassName={'page-link'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    pageCount={totalPages}
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={10}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    forcePage={currentPage - 1}
                                    onPageChange={(page) => {
                                        console.log("Selected page:", page);
                                        this.SetCurrentPage(page.selected + 1);
                                        document.body.scrollTop = 0; // For Safari
                                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                    }}
                                    containerClassName={'pagination mb-0'}
                                    activeClassName={'active'}
                                />
                                {totalRecords >= 10000 && <i className="fas fa-exclamation-triangle text-danger" style={{ fontSize: "21px", padding: "5px" }}
                                    title="Pagination will only work for first 10,000 results. Reduce your time range." />}

                                <div className='ml-auto'>Displaying {recordsPerPage} of total {totalRecords} records.</div>
                            </div>
                        }

                        <ImageModal
                            title={<span style={{ fontWeight: 600 }}>
                                {moment(selectedLog?.timestamp).utc().format('DD-MM-YYYY HH:mm:ss')}
                                <small style={{ color: "black" }}> by <em>{selectedLog?.user_name}</em> on <em>{selectedLog?.computerName}</em> </small>
                                <small>(<a href={selectedLog?.logUrl} target="_blank">Graylog</a>)</small>
                            </span>}
                            imgSrc={`${process.env.REACT_APP_TELEMETRY_SERVICE_URL}/api/screenshots/${selectedLog?.screenshotFileName}?access_token=${this.props.accessToken}`}
                            show={showImageModal}
                            onHide={() => { this.setState({ selectedLog: undefined, showImageModal: false }); }}
                            onNextClick={() => { this.onNextImageClick() }}
                            onPreviousClick={() => { this.onPreviousImageClick() }}
                            showPreviousButton={!(currentPage == 1 && selectedLogIndex == 0)}
                            showNextButton={!(currentPage == totalPages && selectedLogIndex == logMessages.length - 1)}
                        />

                        {!!downloadRequestId &&
                            <DownloadScreenshotsProgressDialog
                                accessToken={this.props.accessToken}
                                show={showDownloadScreenshotsProgressDialog}
                                downloadRequestId={downloadRequestId!}
                                onHide={() => { this.setState(s => ({ ...s, downloadRequestId: undefined, showDownloadScreenshotsProgressDialog: false })) }}
                            />}

                    </Card.Body>
                </Card>
                }
                {!isLoading && logMessages.length == 0 &&
                    <Card>
                        <Card.Body className='text-center'>
                            It seems that no screenshots were found with the given filters.<br />
                            Please consider updating your filters and trying again.
                        </Card.Body>
                    </Card>}
            </ContentWrapper>
        </div >

    }
}

export const UtcTimePopover = (
    <Popover id="popover-positioned-top" >
        <span style={{ padding: "5px" }}> UTC</span>
    </Popover>
);

export default ScreenshotsPage;