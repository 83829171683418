import * as React from "react";
import { Card } from "react-bootstrap";
import ContentWrapper from '../../layout/components/content-wrapper/content-wrapper.component';
import SubHeader from '../../layout/components/sub-header/sub-header.component';
import * as  QueryString from 'query-string';
import ReactPaginate from "react-paginate";
import { OrganizationResultModel, OrganizationsClient } from "../../swagger-clients/dashboard-clients.service";

import { toast } from "react-toastify";
import { TableHeaderItem } from "../../components/table-header-item/table-header-item.component";
import EditOrganizationModal from "./edit-organization-modal/edit-organization-modal.component";
import DetailsOrganizationModal from "./details-organization-modal/details-organization-modal.component";
import DeleteOrganizationModal from "./delete-organization-modal/delete-organization-modal.component";
import { getOrganizationsClient } from "../../services/api-clients.service";
import { AuthenticationClient } from "../../swagger-clients/s365-admin-panel-clients.service";
import LoadingService from "../../components/loading-indicator/loading-indicator.service";
import SyncUsersModal from "./sync-users-modal/sync-users-modal.component";

interface IOrganizationTableProps {

}

interface IOrganizationTableState {
    organizations: OrganizationResultModel[];
    currentPage: number;
    recordsPerPage: number;
    totalRecords: number;
    sortBy: string;
    sortDescending: boolean;
    showAddOrganizationModal: boolean;
    showDetailsModal: boolean;
    showSyncUsersModal: boolean;
    showDeleteModal: boolean;
    selectedOrganizationId?: number;
}

class OrganizationTable extends React.Component<IOrganizationTableProps, IOrganizationTableState>{


    constructor(props: IOrganizationTableProps) {
        super(props);
        this.state = {
            organizations: [],
            currentPage: 1,
            recordsPerPage: 50,
            sortBy: "Name",
            sortDescending: false,
            totalRecords: 0,
            showAddOrganizationModal: false,
            showDetailsModal: false,
            showDeleteModal: false,
            showSyncUsersModal: false
        };
    }

    async componentDidMount() {
        this.getOrganizations();
    }

    async getOrganizations() {
        try {
            LoadingService.showLoading();
            const { sortBy, sortDescending, recordsPerPage, currentPage } = this.state;
            const skip = (currentPage - 1) * recordsPerPage;
            const client = getOrganizationsClient();
            const organizationsResult = await client.getOrganizations(null, null, sortBy, sortDescending, skip, recordsPerPage);

            this.setState({
                organizations: organizationsResult.items ? organizationsResult.items : [],
                totalRecords: organizationsResult.totalRecords ?? 0
            });
        }
        catch (error) {
            console.log("An error occurred while trying to get organizations.", error);
            toast.error("An error occurred while trying to get organizations.");
        } finally {
            LoadingService.hideLoading();
        }
    }


    onColumnClick(column: string) {
        const { sortBy, sortDescending } = this.state;
        if (sortBy == column) {
            this.setState({ sortBy: column, sortDescending: !sortDescending }, () => {
                this.getOrganizations();
            });
        } else {
            this.setState({ sortBy: column, sortDescending: false }, () => {
                this.getOrganizations();
            });
        }
    }

    SetCurrentPage(page: number) {
        this.setState({ currentPage: page }, () => {
            this.getOrganizations();
        });
    }

    render() {

        const { organizations, sortBy, sortDescending, totalRecords, recordsPerPage,
            showAddOrganizationModal, showDetailsModal, showDeleteModal, selectedOrganizationId, showSyncUsersModal } = this.state;

        const selectedOrg = selectedOrganizationId ? organizations.find(x => x.id == selectedOrganizationId) : undefined;

        const totalPages = Math.ceil(totalRecords / recordsPerPage);

        const headerItems = [
            <button className="btn btn-primary mr-2" onClick={() => this.setState({ showAddOrganizationModal: true })}>Add Organization</button>,
            <button className="btn btn-secondary" onClick={() => this.setState({ showSyncUsersModal: true })}>Sync Users</button>
        ];

        return [<SubHeader key="sub-header" title="Organizations" leftItems={[]} rightItems={headerItems} />,
        <ContentWrapper key="content-wrapper">
            <Card style={{ width: "1200px", marginRight: "auto", marginLeft: "auto" }}>

                <Card.Body>
                    <table className="table">
                        <thead>
                            <tr>
                                <TableHeaderItem
                                    title={"Name"}
                                    isSorted={sortBy == "Name"}
                                    sortDescending={sortDescending}
                                    onClick={() => this.onColumnClick.call(this, "Name")}
                                />
                                <TableHeaderItem
                                    title={"Azure group name"}
                                    isSorted={sortBy == "AzureGroupName"}
                                    sortDescending={sortDescending}
                                    onClick={() => this.onColumnClick.call(this, "AzureGroupName")}
                                />

                                <TableHeaderItem
                                    title={"RMS server"}
                                    isSorted={false}
                                />
                                <th></th>

                            </tr>
                        </thead>
                        <tbody>

                            {organizations.map((organization) => (<tr>
                                <td>{organization.name}</td>
                                <td>{organization.azureGroupName}</td>
                                <td>{organization.rmsServer} </td>
                                <td className="text-right"><button type="button"
                                    className="btn btn-sm btn-primary"
                                    onClick={() => {
                                        this.setState({
                                            showAddOrganizationModal: true,
                                            selectedOrganizationId: organization.id
                                        })
                                    }}>Edit</button>

                                    <button type="button"
                                        className="btn btn-sm btn-secondary ml-2"
                                        onClick={() => {
                                            this.setState({
                                                showDetailsModal: true,
                                                selectedOrganizationId: organization.id
                                            })
                                        }}>Details</button>

                                    <button type="button"
                                        className="btn btn-sm btn-danger ml-2"
                                        onClick={() => {
                                            this.setState({
                                                showDeleteModal: true,
                                                selectedOrganizationId: organization.id
                                            })
                                        }}>Delete</button>
                                </td>
                            </tr>))
                            }

                        </tbody>
                    </table>

                    {totalPages > 1 && <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me page-item'}
                        breakLinkClassName={'page-link'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        pageCount={totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        onPageChange={(page) => { console.log("Selected page:", page); this.SetCurrentPage(page.selected + 1); }}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                    }

                    {
                        showAddOrganizationModal &&
                        <EditOrganizationModal
                            show={true}
                            organizationId={selectedOrganizationId}
                            onHide={() => this.setState({ showAddOrganizationModal: false, selectedOrganizationId: undefined })}
                            onOrganizationChanged={() => { this.getOrganizations(); this.setState({ showAddOrganizationModal: false, selectedOrganizationId: undefined }); }} />
                    }

                    {
                        showDetailsModal &&
                        <DetailsOrganizationModal
                            show={true}
                            organizationId={selectedOrganizationId ?? 0}
                            onHide={() => this.setState({ showDetailsModal: false, selectedOrganizationId: undefined })}
                        />
                    }
                    {
                        showDeleteModal &&
                        <DeleteOrganizationModal
                            show={true}
                            organizationName={selectedOrg && selectedOrg.name ? selectedOrg.name : ""}
                            organizationId={selectedOrganizationId ?? 0}
                            onHide={() => this.setState({ showDeleteModal: false, selectedOrganizationId: undefined })}
                            onOrganizationDeleted={() => { this.setState({ showDeleteModal: false, selectedOrganizationId: undefined }); this.getOrganizations(); }}
                        />
                    }
                    {
                        showSyncUsersModal && <SyncUsersModal show={true} onHide={() => this.setState({ showSyncUsersModal: false })} />
                    }


                </Card.Body>
            </Card>
        </ContentWrapper>];
    }

}


export default OrganizationTable;