import * as React from "react";
import SidebarPlaceHolderService from "./sidebar-placeholder-service";

interface ISideBarPlaceholderState {
    element?: JSX.Element;
}

class SideBarPlaceholder extends React.Component<{}, ISideBarPlaceholderState>{

    constructor(props: {}) {
        super(props);
        this.state = {};
       
    }
    componentDidMount() {
        SidebarPlaceHolderService.addCallback((e: any) => this.callback(e));
    }


    callback(element?: JSX.Element) {
        this.setState({ element: element });
    }

    render() {
        const { element } = this.state;

        return <div className="sidebar-placeholder" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {element ? element : ""}
        </div>
    }
}


export default SideBarPlaceholder;