import React from "react";
import targetProcessLogo from "../../assets/images/target-process-logo.png";

type TargetProcessLinkProps = {
    targetProcesMotherTicketId: number;
}

export const TargetProcessLink: React.FC<TargetProcessLinkProps> = (props) => {


    return <a href={`https://capitalgain.tpondemand.com/entity/${props.targetProcesMotherTicketId}`}
        target="_blank"
        onClick={(ev) => ev.stopPropagation()}
        style={{ marginLeft: "20px" }}
    >
        <img height={"20px"} src={targetProcessLogo} alt="Target process logo" />
        <span className="ml-2">#{props.targetProcesMotherTicketId}</span>
    </a>

}