import * as React from "react";
import { Modal } from 'react-bootstrap';
import nl2br from "react-nl2br";
import { toast } from "react-toastify";
import LoadingService from "../../../components/loading-indicator/loading-indicator.service";
import { HostsClient, NotificationPostModel, UserSessionModel } from "../../../swagger-clients/s365-admin-panel-clients.service";

interface IUserNotificationModalProps {
    hostPoolName: string;
    resourceGroupName: string;
    show: boolean;
    onHide: () => void;
}

interface IHostSessionPool {
    name: string;
    userSessions: UserSessionModel[];
    selected: boolean;
}

const RadioMessages = [
    { id: 0, title: "Urgent maintenance", message: "We must perform urgent maintenance. This machine will be restarted during the maintenance process.\n\nPlease save all your work immediately and log out.\nYou may log in again after 5 minutes." },
    { id: 1, title: "Software update required", message: "We need to update your machine. This machine will be restarted during the update process.\n\nPlease save all your work immediately and log out.\nYou may log in again after 5 minutes." },
    { id: 2, title: "Custom message", message: null }
];




const UserNotificationModal: React.FunctionComponent<IUserNotificationModalProps> = (props) => {

    const [selectedMessage, setSelectedMessage] = React.useState<number>(0);
    const [messageTitle, setMessageTitle] = React.useState<string>("");
    const [customMessage, setCustomMessage] = React.useState<string>("");
    const [isLoading, SetIsLoading] = React.useState<boolean>(false);
    const [sessionPools, setSessionPools] = React.useState<IHostSessionPool[]>([]);


    React.useEffect(() => {
        getHostSessionPools();
    }, []);


    const getHostSessionPools = async () => {

        try {
            LoadingService.showLoading();
            if (props.hostPoolName !== "") {
                const client = new HostsClient(process.env.REACT_APP_BASE_URL);
                const response = await client.getSessionHosts(props.resourceGroupName, props.hostPoolName);
                setSessionPools(response);
            }

        } catch (error) {
            console.log("An error occurred while trying to get Host session pools.", error);
            toast.error("An error occurred while trying to get Host session pools.");
        } finally {
            LoadingService.hideLoading();
        }
    }

    const onRadioSelectionChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const value = +ev.target.value;
        setSelectedMessage(value);
    }

    const HasSelectedRecievers = (): boolean => {
        let hasRecievers = false;

        for (var i = 0; i < sessionPools.length; i++) {
            if (hasRecievers)
                break;
            for (var j = 0; j < sessionPools[i].userSessions.length; j++) {
                if (sessionPools[i].userSessions[j].selected) {
                    hasRecievers = true;
                    break;
                }
            }
        }
        return hasRecievers;
    }

    const onCustomMessageChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = ev.target.value;
        setCustomMessage(value);
    }
    const onSendButtonClick = async () => {
        try {
            if (!HasSelectedRecievers()) {
                toast.error("You need to select users.");
            } else {
                const client = new HostsClient(process.env.REACT_APP_BASE_URL);

                let message = null;
                let title = null;

                // Custom message
                if (selectedMessage == RadioMessages.length - 1) {
                    if (!messageTitle.trim()) {
                        toast.error("Message title is required.");
                        return;
                    }
                    else if (!customMessage.trim()) {
                        toast.error("Message is required.");
                        return;
                    }

                    title = messageTitle.trim();
                    message = customMessage.trim();
                }
                else {
                    title = RadioMessages[selectedMessage].title;
                    message = RadioMessages[selectedMessage].message;
                }

                const postModel = {
                    message: message,
                    title: title,
                    sessionHosts: sessionPools

                } as NotificationPostModel;

                SetIsLoading(true);
                const response = await client.sendNotifications(postModel, props.resourceGroupName, props.hostPoolName);
                if (response.isStatusSuccess) {
                    toast.success("Notification sent to all selected users.");
                } else {
                    const notSentToUsers = response.notSentToUsers.reduce((prev, curr) => prev + "," + curr);
                    toast.error(`Failed to send notification to users: ${notSentToUsers}`);
                }

            }
        }
        catch (error) {
            console.log("An error ocurred while sending notification.", error);
            toast.error("An error ocurred while sending notification.");
        }
        finally {
            SetIsLoading(false);
        }
    }

    const onSessionPoolChecked = (ev: React.ChangeEvent<HTMLInputElement>, sessionPoolName: string) => {
        let sessionPool = sessionPools.find(x => x.name === sessionPoolName);

        const isSelected = ev.target.checked;
        if (sessionPool) {
            sessionPool.selected = isSelected;
            sessionPool.userSessions = sessionPool.userSessions.map((userSession) => (new UserSessionModel({
                userSessionName: userSession.userSessionName,
                userName: userSession.userName,
                sessionState: userSession.sessionState,
                selected: userSession.sessionState == "Active" ? isSelected : false
            })));

            const filterSessionPools = sessionPools.filter((sessionPool) => sessionPool.name !== sessionPoolName);
            setSessionPools([...filterSessionPools, sessionPool]);

        }
    }


    const onMessageTitleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const value = ev.target.value;
        setMessageTitle(value);
    }

    const onUserSessionChecked = (ev: React.ChangeEvent<HTMLInputElement>, sessionPoolName: string, userSessionName: string) => {
        const selected = ev.target.checked;
        let sessionPool = sessionPools.find(x => x.name === sessionPoolName);
        if (sessionPool) {
            if (sessionPool.selected) {
                sessionPool.selected = selected;
            }
            sessionPool.userSessions = sessionPool.userSessions.map((userSession) => {
                if (userSession.userSessionName == userSessionName) {
                    return new UserSessionModel({ ...userSession, selected: selected });
                }
                return userSession;

            });

            const filterSessionPools = sessionPools.filter((sessionPool) => sessionPool.name !== sessionPoolName);
            setSessionPools([...filterSessionPools, sessionPool]);

        }
    }

    const onUserLogoutClick = async (sessionPool: IHostSessionPool, userSession: UserSessionModel) => {
        try {
            SetIsLoading(true);

            const client = new HostsClient(process.env.REACT_APP_BASE_URL);
            await client.logoutUser(props.resourceGroupName, props.hostPoolName, sessionPool.name, userSession.userSessionName);
            toast.success("User successfully logged out. It takes some time to propagate, please refresh after few seconds.");

            getHostSessionPools();

        } catch (error) {
            console.log("An error ocurred while logging out user.", error);
            toast.error("An error ocurred while logging out user.");
        }
        finally {
            SetIsLoading(false);
        }

    }

    return <Modal
        show={props.show}
        onHide={props.onHide}
        size="xl"
        animation={false}>

        <Modal.Header closeButton={true}>

            <Modal.Title>
                Send user notification to <em>{props.hostPoolName}</em> users
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row">
                <div className="form col-5">



                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Message:</label>
                        <div className="col-sm-9">
                            {RadioMessages.map((radioMessage) => {
                                return <div className="form-check mb-2" key={radioMessage.id}>
                                    <label className="form-check-label">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            checked={radioMessage.id == selectedMessage}
                                            value={radioMessage.id}
                                            onChange={(ev) => onRadioSelectionChanged(ev)}
                                            name="radiomessages" />{radioMessage.title}<br /><small>{nl2br(radioMessage.message)}</small>
                                    </label>
                                </div>
                            })}




                        </div>
                    </div>

                    {selectedMessage === RadioMessages.length - 1 && <>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Title:</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(ev) => { onMessageTitleChange(ev) }} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Message:</label>
                            <div className="col-sm-9">
                                <textarea
                                    className="form-control mt-4"
                                    value={customMessage}
                                    onChange={(ev) => onCustomMessageChange(ev)}
                                    rows={5}
                                ></textarea>
                            </div>
                        </div>
                    </>}

                </div>

                <div className="col-7 overflow-auto" style={{ maxHeight: "73vh" }}>
                    <button className="btn btn-sm btn-primary float-right" onClick={() => getHostSessionPools()}>Refresh</button>

                    {sessionPools.map(sessionPool => {
                        return <div className="" key={sessionPool.name}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{ width: "20px" }}></th>
                                        <th>Session pool / Username</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="table-active">
                                        <td> <input type="checkbox"
                                            checked={sessionPool.selected}
                                            onChange={(ev) => { onSessionPoolChecked(ev, sessionPool.name) }} /> </td>
                                        <td>{sessionPool.name} </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    {sessionPool.userSessions &&
                                        sessionPool.userSessions.map((userSession) => {
                                            return <tr key={userSession.userSessionName} >
                                                <td className="align-middle" style={{ paddingLeft: 40 }}> <input type="checkbox"
                                                    checked={userSession.selected}
                                                    disabled={userSession.sessionState !== "Active"}
                                                    onChange={(ev) => { onUserSessionChecked(ev, sessionPool.name, userSession.userSessionName) }}
                                                /> </td>
                                                <td className="align-middle">{userSession.userName}</td>
                                                <td className="align-middle">{userSession.sessionState}</td>
                                                <td className="align-middle">
                                                    <button className="btn btn-sm btn-secondary" onClick={() => onUserLogoutClick(sessionPool, userSession)}>Logout</button>
                                                </td>
                                            </tr>
                                        })}
                                </tbody>
                            </table>
                        </div>
                    })}
                </div>
            </div>

        </Modal.Body>

        <Modal.Footer>
            <div className="form-group d-flex float-right">
                <button type="button" onClick={onSendButtonClick} className={'btn btn-primary'} disabled={isLoading}>
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
                    {"Send"}
                </button>
                <button type="button" onClick={props.onHide} className={'btn btn-danger ml-2'} disabled={isLoading}>
                    {"Cancel"}
                </button>

            </div>
        </Modal.Footer>

    </Modal >
}

export default UserNotificationModal;