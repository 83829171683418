import React from "react";
import SubHeader from "../../layout/components/sub-header/sub-header.component";
import ContentWrapper from "../../layout/components/content-wrapper/content-wrapper.component";
import { Card, Form } from "react-bootstrap";
import { DwsimProUserAnalyticsResponseModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import { getMsAnalyticsClient } from "../../services/api-clients.service";
import { _copyAndSortWithSecondaryColumn } from "../../utilities/array.helpers";

type DwsimProAnalyticsProps = {

}

type DwsimProUserAnalyticsResponseModelProperties = keyof DwsimProUserAnalyticsResponseModel | string;

export const DwsimProAnalytics: React.FC<DwsimProAnalyticsProps> = (props) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [userAnalytics, setUserAnalytics] = React.useState<DwsimProUserAnalyticsResponseModel[]>([]);
    const [orderBy, setOrderBy] = React.useState<DwsimProUserAnalyticsResponseModelProperties>("isPaid");
    const [orderByDescending, setOrderByDescending] = React.useState<boolean>(true);
    const [hideTestUsers, setHideTestUsers] = React.useState<boolean>(true);
    React.useEffect(() => {
        getUserAnalyticsData();
    }, [hideTestUsers]);

    React.useEffect(() => {

        orderColumns(orderBy, orderByDescending);


    }, [orderBy, orderByDescending]);

    const getUserAnalyticsData = async () => {
        try {
            setIsLoading(true);
            const client = getMsAnalyticsClient();
            const resp = await client.getAnalytics(hideTestUsers);
            const sortedUserAnalytics = _copyAndSortWithSecondaryColumn<DwsimProUserAnalyticsResponseModel>(resp, orderBy, "username", orderByDescending);
            setUserAnalytics(sortedUserAnalytics);


        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while getting DWSIM Pro analytics.");
        } finally {
            setIsLoading(false);
        }
    }

    const getSortValue = (columnName: DwsimProUserAnalyticsResponseModelProperties) => {

        if (orderBy == columnName) {
            if (orderByDescending) {
                return "desc";
            } else {
                return "asc";
            }
        }
        return undefined;
    }

    const onColumnClick = (column: DwsimProUserAnalyticsResponseModelProperties) => {
        if (orderBy == column) {
            setOrderByDescending(!orderByDescending);
        } else {
            setOrderBy(column);
            setOrderByDescending(true);
        }
    }

    const orderColumns = (orderByVal: DwsimProUserAnalyticsResponseModelProperties, isDescending: boolean) => {


        const sortedUserAnalytics = _copyAndSortWithSecondaryColumn<DwsimProUserAnalyticsResponseModel>(userAnalytics, orderByVal, "username", isDescending);
        setUserAnalytics(sortedUserAnalytics);


    }
    const leftItems = [

        <div className="col-auto mr-2">
            <Form.Check type="checkbox"
                checked={hideTestUsers}
                onChange={(ev: any) => { setHideTestUsers(ev.target.checked); }}
                label="Hide test users" />
        </div>

    ];

    return <>
        <SubHeader key="sub-header" title="DWSIM Pro Analytics" leftItems={leftItems} rightItems={[]} />
        <ContentWrapper key="content-wrapper" className="pl-4 pr-4">
            <Card>
                <Card.Body>

                    <div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className={"align-middle sortable " + getSortValue("username")} onClick={() => { onColumnClick("username") }}>Username</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("openedFromDashboard")} onClick={() => { onColumnClick("openedFromDashboard") }}>Open from Dashboard</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("newFlowsheet")} onClick={() => { onColumnClick("newFlowsheet") }}>New Flowsheet</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("solveFlowsheet")} onClick={() => { onColumnClick("solveFlowsheet") }}>Solve Flowsheet</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("converged")} onClick={() => { onColumnClick("converged") }}>Converged</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("notConverged")} onClick={() => { onColumnClick("notConverged") }}>Not converged</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("propertyPackages")} onClick={() => { onColumnClick("propertyPackages") }}>Property packages</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("objects")} onClick={() => { onColumnClick("objects") }}>Objects</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("components")} onClick={() => { onColumnClick("components") }}>Components</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("jsonImport")} onClick={() => { onColumnClick("jsonImport") }}>JSON import</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("onlineImport")} onClick={() => { onColumnClick("onlineImport") }}>Online import</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("dynamicStarted")} onClick={() => { onColumnClick("dynamicStarted") }}>Dynamic started</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("dynamicFailed")} onClick={() => { onColumnClick("dynamicFailed") }}>Dynamic failed</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("sensitivityStudy")} onClick={() => { onColumnClick("sensitivityStudy") }}>Sensitivity study</th>
                                    <th className={"align-middle sortable text-center " + getSortValue("reporting")} onClick={() => { onColumnClick("reporting") }}>Reporting</th>
                                </tr>
                            </thead>
                            <tbody>

                                {userAnalytics && userAnalytics.length > 0 && userAnalytics.map((user,i) => (
                                    <tr key={`${user.username}-${i}`}
                                        className={!!user.isPaid ? "table-info" : undefined}
                                        style={{ cursor: "default" }}
                                    >
                                        <td className="align-middle">
                                            {user.username}
                                        </td>
                                        <td className="text-center align-middle">{user.openedFromDashboard ?? "-"}</td>
                                        <td className="text-center align-middle">{user.newFlowsheet ?? "-"}</td>
                                        <td className="text-center align-middle">{user.solveFlowsheet ?? "-"}</td>
                                        <td className="text-center align-middle">{user.converged ?? "-"}</td>
                                        <td className="text-center align-middle">{user.notConverged ?? "-"}</td>
                                        <td className="text-center align-middle">{user.propertyPackages ?? "-"}</td>
                                        <td className="text-center align-middle">{user.objects ?? "-"}</td>
                                        <td className="text-center align-middle">{user.components ?? "-"}</td>
                                        <td className="text-center align-middle">{user.jsonImport ?? "-"}</td>
                                        <td className="text-center align-middle">{user.onlineImport ?? "-"}</td>
                                        <td className="text-center align-middle">{user.dynamicStarted ?? "-"}</td>
                                        <td className="text-center align-middle">{user.dynamicFailed ?? "-"}</td>
                                        <td className="text-center align-middle">{user.sensitivityStudy ?? "-"}</td>
                                        <td className="text-center align-middle">{user.reporting ?? "-"}</td>
                                    </tr>))
                                }

                                {isLoading &&
                                    <tr>
                                        <td colSpan={10} className="text-center">Loading...</td>
                                    </tr>
                                }
                                {!isLoading && userAnalytics.length == 0 &&
                                    <tr>
                                        <td colSpan={10} className="text-center">No user analytics found.</td>
                                    </tr>
                                }


                            </tbody>
                        </table>


                    </div>


                </Card.Body>
            </Card>
        </ContentWrapper>
    </>
}